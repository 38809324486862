@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?21m3mb');
  src:  url('fonts/icomoon.eot?21m3mb#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?21m3mb') format('truetype'),
    url('fonts/icomoon.woff?21m3mb') format('woff'),
    url('fonts/icomoon.svg?21m3mb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lobby:before {
  content: "\e90e";
}
.icon-ic-time:before {
  content: "\e90d";
}
.icon-back:before {
  content: "\e90b";
}
.icon-next:before {
  content: "\e90c";
}
.icon-down-arrow:before {
  content: "\e90a";
}
.icon-screen-rotate:before {
  content: "\e909";
}
.icon-close:before {
  content: "\e906";
}
.icon-hash-code:before {
  content: "\e907";
}
.icon-search-up:before {
  content: "\e908";
}
.icon-home:before {
  content: "\e905";
}
.icon-game:before {
  content: "\e900";
}
.icon-help:before {
  content: "\e901";
}
.icon-History:before {
  content: "\e902";
}
.icon-info:before {
  content: "\e903";
}
.icon-minus:before {
  content: "\e904";
}
.icon-plus:before {
  content: "\e956";
}
.icon-random:before {
  content: "\e957";
}
.icon-reset:before {
  content: "\e958";
}
.icon-Result:before {
  content: "\e959";
}
.icon-search:before {
  content: "\e95a";
}
.icon-warning_notification:before {
  content: "\e95b";
}
.icon-animal-_notification_icon:before {
  content: "\e95c";
}
.icon-arrow_back:before {
  content: "\e95d";
}
.icon-arrow_drop_down:before {
  content: "\e95e";
}
.icon-arrow_left:before {
  content: "\e95f";
}
.icon-arrow_right:before {
  content: "\e960";
}
.icon-bet:before {
  content: "\e961";
}
.icon-bet_notification:before {
  content: "\e962";
}
.icon-clear:before {
  content: "\e963";
}
.icon-close_filter:before {
  content: "\e964";
}
.icon-Error_notification:before {
  content: "\e965";
}
.icon-filter:before {
  content: "\e966";
}
