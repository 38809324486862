//Right column start----------------------------------------
.r-col-grid {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: grid;
  grid-template-areas: "user-info-row" "statistics";
  grid-template-rows: auto 11fr;
  grid-row-gap: 8px;
}

.user-balance-row {
  width: 100%;
  grid-area: user-info-row;
}

.u-id-balance-holder {
  height: 36px;
  width: 100%;
  padding: 0 10px;
  border-radius: 6px;
  background: rgba(var(--skin-color-2), 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.u-id-v {
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 14px;
  color: rgba(var(--text-color), 0.85);
  z-index: 999;
  position: relative;
}

.user-nav-icon {
  width: 26px;
  margin-right: 5px;
  position: relative;
 cursor: pointer;


  > img {
    max-width: 26px;
    max-height: 26px;
  }

  > i {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: rgba(var(--hero-color), 1);
    color: rgba(var(--hero-color-set), 1);
    font-size: 14px;
    border-radius: 50%;
    right: 0;
    bottom: 4px;
    width: 10px;
    height: 10px;
  }
}

// user config dropdown Start

.user-config-dropdown {
  position: absolute;
  top: 48px;
  width: 140px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(var(--skin-color-2), 1);
  padding: 10px 12px;
  border-radius: 4px;
  z-index: 99999;
  [dir='ltr'] & {
    left: 0;
    &:before{
      left: 20px;
    }
  }

  [dir='rtl'] & {
   right: 0;
    &:before {
      right: 20px;
    }
  }

  //animation: config-dropdown 180ms ease-in-out;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    top: -3px;
    background-color: rgba(var(--skin-color-2), 1);
    transform: rotate(45deg);
  }
}

.user-config-d-row {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
  margin-bottom: 6px;
  border-bottom: 1px solid rgba(var(--skin-color-1), 1);

  &:last-child {
    padding-bottom: 0;
    border: none;
    margin-bottom: 0;
  }
}

.user-config-d-r-inner {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.user-config-d-r-column {
  cursor: pointer;
  overflow: hidden;

  &:first-child {
    flex: 1;
  }

  &:last-child {
    flex: 0 0 auto;
  }

  > span {
    display: block;
    font-size: 10px;
    line-height: 16px;
    color: rgba(var(--text-color), 1);
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  > i {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: rgba(var(--hero-color), 1);
    color: rgba(var(--hero-color-set), 1);
    font-size: 20px;
    line-height: 22px;
  }
}

.user-config-title {
  flex: 1;
  text-transform: capitalize;
  color: rgba(var(--text-color), 0.65);
  font-size: 10px;
  line-height: 16px;
  margin-bottom: 4px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.switcher-txt {
  display: block;
  font-size: 10px;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 500;
  color: rgba(var(--text-color), 1);
}

.switcher {
  position: relative;
  width: 28px;
  height: 16px;
  border-radius: 9px;
  background: rgba(var(--skin-color-1), 1);
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 16px;
    height: 16px;
    background: rgba(var(--hero-color), 1);
    border-radius: 50%;
    transition: transform 230ms;
  }

  &.active {
    &:before {
      transform: translate(12px, 0);
      background: #00d924;
    }
  }
}

@keyframes config-dropdown {
  0% {
    transform: translate(0, -30px);
  }
  100% {
    transform: translate(0, 0);
  }
}

// user config dropdown END
.balance-v {
  font-size: 10px;
  line-height: 14px;
  color: rgba(var(--brand-color-2-3), 1);
  font-weight: 600;
  position: absolute;
  height: 14px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  [dir='ltr'] & {
    right: 10px;
  }

  [dir='rtl'] & {
   left: 10px;
  }
}

.statistics-holder {
  width: 100%;
  height: 100%;
  position: relative;
  grid-area: statistics;
}


.st-grid-holder {
  position: absolute;
  left: 0;
  top: 0;
  display: grid;
  grid-template-rows: auto 11fr;
  grid-row-gap: 1px;
  height: 100%;
  width: 100%;
}
.st-tabs-row{
  overflow: hidden;
}
.st-tabs-container {
  display: flex;
  width: 100%;
  height: 32px;
}

.st-tub-v {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 14px;
  overflow: hidden;
  background-color: rgba(var(--skin-color-2), 1);
  color: rgba(var(--text-color), 0.45);
  transition: color 240ms, background-color 240ms;
  cursor: pointer;
  padding: 0 4px;
  [dir='ltr'] & {
    &:first-child {
      border-top-left-radius: 6px;
    }
    &:last-child {
      border-top-right-radius: 6px;
    }
  }

  [dir='rtl'] & {
    &:first-child {
      border-top-right-radius: 6px;
    }
    &:last-child {
      border-top-left-radius: 6px;
    }
  }


  &:hover {
    color: rgba(var(--text-color), 1);
  }

  &.active {
    background: rgba(var(--hero-color), 1);
    cursor: default;
    pointer-events: none;
    color: rgba(var(--hero-color-set), 1);
  }
  > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.st-tab-content {
  width: 100%;
  height: 100%;
  background: rgba(var(--skin-color-2), 1);
  border-radius: 0 0 6px 6px;
}

.table-title-row {
  display: flex;
  align-items: center;
  width: 100%;
  height: 22px;
  padding-bottom: 4px;
  background: rgba(var(--skin-color-2), 1);
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 77;

  > p {
    flex: 1;
    padding: 0 5px;
    font-size: 10px;
    line-height: 14px;
    color: rgba(var(--text-color), 0.45);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    [dir='ltr'] & {
      &:first-child {
        padding-left: 10px;
        width: 32%;
      }
      &:last-child {
        text-align: right;
        padding-right: 10px;
      }
    }

    [dir='rtl'] & {
      &:first-child {
        text-align: left;
        padding-right: 10px;
      }
      &:last-child {
        text-align: left;
        width: 32%;
      }
    }
  }
}
.win-cnt-comp {
  display: flex;
  height: 100%;
  min-width: 75px;
  justify-content: center;
  border-radius: 6px;
  align-items: center;
  background-image: linear-gradient(to bottom, #feff72, #fed700 46%, #fe8100);
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 0;
  z-index: 22;
  color: rgba(var(--skin-color-1), 1);
  font-weight: 900;
  padding: 0 8px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 7px;
    top: -40%;
    height: 180%;
    transform: rotate(-30deg);
    background: rgba(#ffffff, 0.5);
    z-index: 1;
  }
  [dir='ltr'] & {
    transform: translate(110%, 0);
    &:before{
      left: 10px;
    }
    &:after{
      left: 21px;
    }
  }

  [dir='rtl'] & {
    transform: translate(-110%, 0);
    &:before{
      right: 10px;
    }
    &:after{
      right: 21px;
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    top: -40%;
    height: 180%;
    transform: rotate(-30deg);
    background: rgba(#ffffff, 0.5);
    z-index: 1;
  }
}
.st-content-info {
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  border-radius: 6px;
  background-color: rgba(var(--skin-color-3), 1);
  margin-bottom: 2px;
  //transition: box-shadow 240ms;
  overflow: hidden;
  //&:hover,
  &.selected {
    box-shadow: 0 0 0 1px #ffbb00 inset;
  }
  &.other-win{
    box-shadow: 0 0 0 1px #00d924 inset;
  }
  &.active {
    pointer-events: none;
    cursor: default;
  }

  &:empty {
    background-color: rgba(var(--skin-color-1), 1);
  }

  > p {
    flex: 1;
    align-items: center;
    padding: 0 5px;
    font-size: 10px;
    line-height: 14px;
    color: rgba(var(--text-color), 1);
    position: relative;

    &.ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 6px;
    }

    [dir='ltr'] & {
      &:first-child {
        width: 32%;
        padding-left: 10px;
      }

      &:last-child {
        text-align: right;
        padding-right: 10px;
        .mini-animals-row{
          justify-content: flex-end;
        }
      }
    }

    [dir='rtl'] & {
      &:first-child {
        text-align: right;
        padding-right: 10px;
      }

      &:last-child {
        width: 32%;
        padding-left: 10px;
      }
    }
    &:first-child {
      color: rgba(var(--text-color), 0.85);
    }

    &:last-child {
      color: rgba(var(--text-color), 0.85);
    }
    &.win {
      height: 100%;
      overflow: unset;
      position: relative;
      .win-cnt-comp {
        [dir='ltr'] & {
          animation: win 240ms linear forwards;
        }

        [dir='rtl'] & {
          animation: win-rtl 240ms linear forwards;
        }
      }
    }
  }
}
.mobile-wrapper{
  .st-content-info{
    > p {
      &.win{
        .win-cnt-comp{
          [dir='ltr'] & {
            animation: win 240ms linear 500ms forwards;
          }

          [dir='rtl'] & {
            animation: win-rtl 240ms linear 500ms forwards;
          }
        }
      }
    }
  }
}

@keyframes win {
  0% {
    transform: translate(110%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes win-rtl {
  0% {
    transform: translate(-110%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
//Right column END------------------------------------------
