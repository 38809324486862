@keyframes landscape-rotate {
  0% {
    transform: rotate(90deg);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  90% {
    transform: rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: rotate(0deg);
    opacity: 0;
  }
}

.game {
  background: rgba(var(--skin-color-2), 0.8) url("../../images/bg-mobile.png") no-repeat 50% 50% / 120% auto !important;
  .game-results-container{
    &:before{
      background-color: rgba(#000000, 0.3);
    }
  }
}

.date-picker-m {
  margin-bottom: 1rem;
}

//Header start------------------------------------------------
.header-m-holder {
  width: 100%;
  height: 3.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.1rem solid rgba(var(--skin-color-1), 1);
  z-index: 10;

  &.game {
    .icon-home {
      font-size: 28px;

      &:before {
        content: "\e95d";
      }
    }
  }
}

.h-m-icon-box {
  width: 3.8rem;
  height: 100%;
  min-width: 3.8rem;
  background-color: rgba(var(--skin-color-2), 1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
}

.balance-holder-m {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0.1rem;
  background-color: rgba(var(--skin-color-2), 1);
  padding: 0 1rem;
}


.balance-m-text {
  font-size: 1rem;
  line-height: 1.4rem;
  color: rgba(var(--brand-color-2-3), 1);

  &:only-child {
    flex: 1;
    text-align: center;
  }
}

.user-menu-holder-m {
  width: 3.8rem;
  height: 100%;
  min-width: 3.8rem;
  max-width: 3.8rem;
  background-color: rgba(var(--skin-color-2), 1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  > img {
    max-width: 2.8rem;
    max-height: 2.8rem;
    display: block;
  }

  > i {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: rgba(var(--hero-color), 1);
    color: rgba(var(--text-color), 1);
    font-size: 1.4rem;
    border-radius: 50%;
    right: 0.5rem;
    bottom: 0.4rem;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
}

//Header END--------------------------------------------------

//Navigation start---------------------------------------------
.navigation-holder {
  width: 100%;
  height: 4rem;
  display: table;
  table-layout: fixed;
  border-top: 0.1rem solid transparent;
  border-bottom: 0.1rem solid transparent;
  border-left: 0.3rem solid transparent;
  border-right: 0.3rem solid transparent;
}

.nav-a-m {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(var(--skin-color-2), 1);
  text-align: center;
  color: rgba(var(--text-color), 0.5);
  transition: color 240ms, background-color 240ms;
  text-decoration: none;
  border-right: 0.1rem solid rgba(var(--skin-color-1), 1);
  position: relative;

  > span {
    display: inline-block;
    vertical-align: top;
    font-size: 2.2rem;
    line-height: 1;
    font-weight: normal;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background: rgba(var(--status-color-2), 1);
      right: 0.2rem;
      top: 0.4rem;
      display: none;
    }

    &.active {
      &:after {
        display: block;
      }
    }

  }

  > i {
    display: block;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.2rem;
    padding-bottom: 0.3rem;

    &.ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 0.6rem;
    }
  }

  &.active {
    background-color: rgba(var(--hero-color), 1);
    color: rgba(var(--text-color), 1);
  }

  [dir='ltr'] & {
    &:first-child {
      border-radius: 0.6rem 0 0 0.6rem;
    }
    &:last-child {
      border-radius: 0 0.6rem 0.6rem 0;
      border: none;
    }
  }

  [dir='rtl'] & {
    &:last-child {
      border-radius: 0.6rem 0 0 0.6rem;
    }
    &:first-child {
      border-radius: 0 0.6rem 0.6rem 0;
      border: none;
    }
  }
}

//Navigation END-----------------------------------------------

//Content start------------------------------------------------
.game-top-r-b {
  width: 100%;
}

//Timer start------------------------------
.timer-container {
  width: 100%;
  color: rgba(var(--text-color), 1);
  font-size: 28px;
  height: 56px;
  position: relative;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    left: 0;
    line-height: 56px;
    transform: translate3d(0, 0, 0);
  }
}
//Timer END--------------------------------

//Bet type start---------------------------
.bet-type-container-m {
  width: 100%;
  height: 3rem;
  background: rgba(var(--hero-color), 1);
  border-radius: 0.6rem 0.6rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.9rem;
}

.type-text-box-m {
  width: 100%;
  text-align: center;

  > p {
    color: rgba(var(--text-color), 1);
    font-size: 1rem;
    line-height: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    [dir='ltr'] & {
      padding: 0 0.5rem 0 5rem;
    }

    [dir='rtl'] & {
      padding: 0 5rem 0 0.5rem;
    }
    > span {
      font-size: 2.4rem;
    }
  }
}

//Bet type END-----------------------------

.mobile-game-grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 10fr auto;
  grid-template-areas: "game-title-m" "game-table-m" "bet-action-m";
  padding: 0 0.3rem;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(var(--skin-color-1), 0.8);
    z-index: 99;
    display: none;
  }

  &.disabled-type-1 {
    &:before {
      display: block;
    }

    .ch-title-text-r,
    .bet-amount-row {
      opacity: 0.2;
    }

    .game-action-row,
    .game-table-row {
      z-index: 999;
    }

    .game-items-holder {
      border-color: rgba(var(--brand-color-2-3), 1);
    }

    .g-item {
      border-color: rgba(var(--brand-color-2-3), 1);
    }

    .ch-numbers-b {
      transform: scale(1.2);
    }
  }
}

.m-grid-title-row {
  width: 100%;
  grid-area: game-title-m;

  .bet-type-container-m {
    .type-btn {
      opacity: 1;
    }
  }
}

.mob-game-t-holder {
  width: 100%;
  height: 100%;
  grid-area: game-table-m;
  position: relative;
  z-index: 99;

  .g-item-numbers {
    font-size: 1rem;
    word-spacing: inherit;
  }

  .g-item > img {
    bottom: 4vw;
    //bottom: 14px;
  }
}

.mob-action-holder {
  width: 100%;
  grid-area: bet-action-m;
  z-index: 999;
  position: relative;
}

//Content END--------------------------------------------------
.mob-search-num {
  width: 100%;
  padding: 0 3px;
  background-color: rgba(var(--skin-color-1), 1);
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 999999;

  .numpad {
    margin: 0;
    position: relative;
    grid-template-columns: repeat(4, 1fr);
    max-height: 0;
    transition: max-height 130ms;
    overflow: hidden;

    > span {
      font-size: 12px;

      &:last-child {
        background-color: rgba(var(--hero-color), 1);
      }
    }

    &.open {
      max-height: 200px;
      box-shadow: 0 0 40px 40px rgba(0, 55, 84, 0.8);
    }
  }
}

// web to mobile START

.betHistory,
.lastResult {
  .m-scroll-b {
    padding: 0 0.3rem;
  }
}

// web to mobile END
.m-bet-type-holder {
  padding: 10px 3px;
  width: 100%;
  height: 100%;
  background: rgba(var(--skin-color-2), 1);
}

.m-last-result-list-holder {
  padding: 10px 3px;
  width: 100%;
  height: 100%;
}

.help-m-content {
  padding: 1rem 0.3rem;
}

.game-h-inner {
  padding: 0 3px;
}

// bet history START

.bet-history-m-head {
  height: 3.8rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(var(--skin-color-1), 1);
  align-items: center;
  background: rgba(var(--skin-color-2), 1);
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 99;

  > i {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    border-right: 0.1rem solid rgba(var(--skin-color-1), 1);
    border-left: 0.1rem solid rgba(var(--skin-color-1), 1);
    font-size: 2.8rem;
  }

  > span {
    font-size: 1.8rem;
  }
}

.bet-history-m-holder {
  display: grid;
  grid-template-rows: auto 10fr;
  grid-template-columns: 12fr;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  .ch-title-cnt {
    padding: 1.4rem 1rem 1rem 1rem;
  }

  .bet-history-count-info {
    min-width: 20.6rem;
    height: 4rem;
    font-size: 2rem;
    border-radius: 0.6rem;
    margin: 1.4rem auto;
  }

  .money-v {
    font-size: 2.8rem;
  }

  .win-text {
    font-size: 10px;
    line-height: 14px;
  }
}

.bet-history-m-content {
  width: 100%;
  position: relative;
  height: 100%;
}

.bet-history-m-c-inner {
  display: grid;
  grid-template-rows: 2fr 2fr 8fr;
  grid-template-columns: 12fr;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    display: block;
    border-radius: 50%;
    width: 810px;
    height: 810px;
    top: 0;
    left: 50%;
    background: rgba(var(--skin-color-1), 0.2);
    transform: translate(-50%, -73%);
  }

  .bet-history-h-row {
    &:last-child {
      margin: 0;
      height: 100%;
    }
  }
}

.result-r-cnt-m {
  display: grid;
  grid-template-rows: auto 11fr;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(var(--skin-color-2), 1);
  left: 0;
  top: 0;
  padding: 10px 3px;
}

.result-r-head {
  width: 100%;
}

.result-r-content {
  position: relative;
  width: 100%;
}

.result-r-h {
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.result-r-row {
  display: flex;
  width: 100%;
  height: 32px;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
}

.result-column {
  flex: 1;
  display: flex;
  padding: 0 5px;
  position: relative;
  border-right: 1px solid rgba(var(--skin-color-1), 1);
  height: 100%;
  align-items: center;

  &:last-child {
    border-right: 0;
  }

  > i {
    color: rgba(var(--text-color), 0.65);
    font-size: 10px;
    font-style: normal;
    margin: 0 4px;
  }

  > span {
    font-size: 10px;
    letter-spacing: 0.5px;
  }
}


// mobile numpad
.mobile-numpad-cnt {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 0.3rem;
  z-index: 9999999999;
}

.mobile-numpad {
  display: grid;
  grid-gap: 0.1rem;
  grid-auto-flow: row;
  border-radius: 0.6rem;
  overflow: hidden;
  max-height: 0;
  transition: max-height 130ms linear;
  text-transform: uppercase;
  font-size: 1.2rem;
  color: rgba(var(--hero-color-set), 1);
  background-color: rgba(var(--skin-color-1), 1);

  &.open {
    max-height: 22rem;
  }

  .span-2 {
    grid-column: span 2;

  }

  .span-3 {
    grid-column: span 3;
  }

  .c-1 {
    background-color: rgba(var(--hero-color), 0.65);

    &.disabled {
      pointer-events: none;
      background-color: rgba(var(--hero-color), 0.45);
      color: rgba(var(--hero-color-set), 0.45);
      cursor: default;
    }
  }

  .c-2 {
    background-color: rgba(var(--skin-color-2), 1);

  }

  .c-3 {
    background-color: rgba(var(--hero-color), 0.45);
    color: rgba(var(--hero-color-set), 0.65);

    &.disabled {
      pointer-events: none;
      background-color: rgba(var(--hero-color), 0.45);
      color: rgba(var(--hero-color-set), 0.45);
      cursor: default;
    }
  }

  .c-4 {
    background-color: rgba(var(--hero-color), 0.85);

    &.disabled {
      pointer-events: none;
      background-color: rgba(var(--hero-color), 0.45);
      color: rgba(var(--hero-color-set), 0.45);
      cursor: default;
    }
  }

  .c-5 {
    background-color: rgba(var(--hero-color), 1);
  }
}

.mobile-n-head,
.mobile-n-content,
.mobile-n-bottom {
  display: grid;
  grid-gap: 0.1rem;
  grid-template-columns: repeat(4, 1fr);

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
  }
}

.mobile-n-head {
  input {
    outline: none;
    background: transparent;
    border: none;
    color: rgba(var(--text-color), 0.65);
    text-align: center;

    &::placeholder {
      color: rgba(var(--text-color), 0.65);
    }
  }
}

.mobile-n-bottom {
  height: 5rem;

  > i {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > button {
    border-radius: 0;
  }
}

// mobile numpad

// mobile hash-code-page start
.hash-page {
  background: rgba(var(--skin-color-2), 1) !important;
}

.hash-m-cnt {
  width: 100%;
  padding: 1rem;
  position: absolute;
  left: 0;
  top: 3.8rem;
  z-index: 10;
}

.hash-icon {
  background-image: url("../../../assets/images/hash.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;

  &.web {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 18px;
    top: -50px;
    cursor: pointer;
    display: block;
  }
}

.hash-search-block {
  display: flex;
  width: 100%;
  height: 3.6rem;
  padding-right: 3.6rem;
  margin-bottom: 1rem;
  position: relative;

  &:nth-child(2) {
    margin-bottom: 1.6rem;
    padding-right: 0;
  }

  &:nth-child(3) {
    margin-bottom: 1.6rem;
  }

  &:last-child {
    padding-right: 0;
    margin-bottom: 0;
  }
}

.key-sait-inp {
  width: 100%;
  height: 100%;
  border-radius: 0.6rem;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: rgba(var(--skin-color-1), 0.65);
  color: rgba(var(--text-color), 1);

  &:last-child {
    margin-right: 0;
  }

  &::placeholder {
    font-size: 1.1rem;
    line-height: 1.2;
    text-transform: capitalize;
    color: rgba(var(--text-color), 0.45);
  }
}

.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 100%;
  border-radius: 0.6rem;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background-image: linear-gradient(to bottom, rgba(var(--brand-color-1-1), 1), rgba(var(--brand-color-1-2), 1));

  &.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.4;
    cursor: default;
  }

  &.blue {
    background-color: rgba(var(--hero-color), 1);
    background-image: none;
  }

  > i {
    font-size: 3rem;
    color: rgba(var(--text-color), 1);
  }

  .icon-search-up {
    font-size: 1.6rem;
  }
}

.search-block-item {
  width: 100%;
  height: 100%;
  border-radius: 0.6rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  background-color: rgba(var(--skin-color-3), 1);
  padding: 0 1rem;
  color: rgba(var(--text-color), 0.65);

  &:only-child {
    margin: 0;
  }

  > p {
    font-size: 1.2rem;
    line-height: 1.2;
    padding-right: 0.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:only-child {
      padding-right: 0;
    }
  }

  > i {
    flex: 0 0 auto;
    font-size: 2rem;
  }
}

// lobby mobile start
.lobby-mobile {
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url("../../images/bg-lobby-web.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    background-color: rgba(var(--skin-color-black), 0.5);
    backdrop-filter: blur(7px);
    top: 0;
    left: 0;
  }

  //&:before {
  //  content: "";
  //  display: block;
  //  width: 24rem;
  //  height: 24rem;
  //  position: absolute;
  //  //right: -120px;
  //  //bottom: -120px;
  //  bottom: 0;
  //  right: 0;
  //  background-image: url("../../images/24.png");
  //  background-repeat: no-repeat;
  //  background-size: 100% auto;
  //  transform: rotate(95deg) translate(50%, -50%);
  //}
}

.talisman-lobby-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1.1rem 1rem;
  z-index: 99;
}

.play-c-buttons-row {
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-column-gap: 1rem;
}

.play-cont-button-wr {
  &:only-child {
    grid-column: span 2;
  }
}

.play-cont-button {
  width: 100%;
  height: 3.4rem;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  line-height: 1.6;
  color: rgba(var(--brand-color-1-set), 1);
  outline: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &.pl-n {
    background-image: linear-gradient(to bottom, rgba(var(--brand-color-1-1), 1), rgba(var(--brand-color-1-2), 1));
    border: solid 1px #006511;
  }

  &.de {
    border: none;
    box-shadow: 0 0 0 0.1rem rgba(var(--skin-color-white), 0.5) inset;
    background-color: transparent;;
  }
}

.play-inner-text-cont {
  width: 100%;

  > p {
    display: flex;
    justify-content: space-between;

    > span {
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
  }
}

//.talisman-lobby-container {
//  width: 100%;
//  height: 100%;
//  display: grid;
//  grid-template-rows: auto 8fr;
//  position: relative;
//  z-index: 99;
//
//}

//.talisman-lobby-c-head {
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  width: 100%;
//}

//.talisman-lobby-img {
//  width: 100%;
//  padding: 0.5rem 0.2rem;
//  position: relative;
//
//  > img {
//    max-width: 100%;
//    height: auto;
//    transform: translate(0, 0.4rem);
//    position: absolute;
//    top: 1rem;
//  }
//}

.talisman-lobby-switches {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  grid-area: top;

  .switch {
    width: 4px;
    height: 4px;
    background-color: rgba(var(--text-color), 0.65);
    margin: 0 10px;
    border-radius: 50%;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      width: 8px;
      height: 8px;
      background-image: linear-gradient(to bottom, rgba(var(--brand-color-1-1), 1), rgba(var(--brand-color-1-2), 1));
    }
  }
}

.talisman-lobby-play-cont-wrp {
  width: 100%;
  height: 100%;
  overflow-x: hidden
}

.play-container-wrp {
  width: 100%;
  height: 100%;
  grid-area: bottom;
  display: flex;
  align-items: flex-end;
}

.play-container-w-inner {
  width: 100%;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    display: block;
    pointer-events: none;
    height: 100%;
    width: 20%;
    background: linear-gradient(to right, rgba(var(--skin-color-2), 0) 0%, rgba(var(--skin-color-1), 0.8) 100%);
    top: 0;
    right: 0;
    z-index: 99;
  }
}

.play-container-w-scrollable {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 1rem;
}

.has-scroll-anim {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 1rem;
  height: 100%;
  width: 2rem;
  font-size: 1.6rem;
  animation: arrow-anim 1s ease-out infinite;
  pointer-events: none;
  z-index: 99;
}

@keyframes arrow-anim {
  0% {
    transform: translateX(10%);
  }
  50% {
    transform: translateX(30%);

  }
  100% {
    transform: translateX(10%);
  }
}

.play-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  min-height: 20rem;
  padding: 1rem 2.4rem;
  background-color: rgba(var(--skin-color-1), 0.85);
  border-radius: 0.6rem;
  box-shadow: 0 0.6rem 0.6rem 0 rgba(var(--skin-color-black), 0.15);
  margin-bottom: 1rem;

  .time {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.1rem;
    font-size: 1.8rem;
    line-height: 1.44;
    color: rgba(var(--text-color), 1);
    padding: 0 0.2rem;
    font-weight: 800;
    margin-bottom: 1.3rem;

    &.passive {
      color: rgba(var(--text-color), 0.5);
    }

    > span {
      display: block;
      transform: translate(-50px, 0);
      opacity: 0;
      animation: time-l 240ms ease-in-out forwards;
    }
  }

  .play-img {
    max-height: 6rem;
  }

  .timer {
    font-size: 1.6rem;
    line-height: 1.31;
    position: relative;
    //color: rgba(var(--text-color), 0.85);
    color: rgba(var(--text-color), 0.65);
    padding: 0 0.4rem;
    margin-bottom: 1.2rem;

    .icon {
      color: rgba(var(--text-color), 0.65);
      padding-right: 0.5rem;
    }
    .small-text {
      word-break: break-word;
      [dir='ltr'] & {
        padding-left: 3rem;
      }

      [dir='rtl'] & {
        padding-right: 3rem;
      }
    }
  }

  .timer-c {
    position: absolute;
    top: 0;
    [dir='ltr'] & {
      left: 0;
    }

    [dir='rtl'] & {
     right: 0;
    }
  }

  .small-text {
    text-align: center;
    font-size: 1.8rem;
    line-height: 2rem;
    color: rgba(var(--text-color), 0.65);

    &.text {
      padding-bottom: 0.4rem;
    }
  }

  .amount {
    font-size: 2.8rem;
    line-height: 3rem;
    color: rgba(var(--status-color-3), 1);
    padding-bottom: 0.8rem;
    font-weight: bold;
  }
}

.play-inner-cont {
  width: 100%;
  text-align: center;
  margin-bottom: 0.9rem;
}

// lobby mobile END
html {
  &.lobby {
    font-size: 3.125vmin;
    .user-config-dropdown {
      top: 4.8rem;
      width: 14rem;
      padding: 1rem 1.2rem;
      border-radius: 0.4rem;
      left: auto;
      right: 0.4rem;
      &:before{
        left: auto;
        right: 1.5rem;
      }
    }
    .h-m-icon-box,
    .user-menu-holder-m {
      width: 3.8rem;
      min-width: 3.8rem;
      font-size: 2.4rem;
    }

    .user-menu-holder-m {
      > img {
        max-width: 2.8rem;
        max-height: 2.8rem;
      }

      > i {
        font-size: 1.4rem;
        right: 0.5rem;
        bottom: 0.4rem;
        width: 1rem;
        height: 1rem;
      }
    }

    .balance-m-text {
      font-size: 1rem;
      line-height: 1.4rem;
    }

    //.header-m-holder {
    //  height: 3.8rem;
    //}

    .balance-holder-m {
      margin: 0 0.1rem;
    }

    .navigation-row {
      &:before {
        height: 7rem;
        bottom: 4rem;
      }
    }

    .nav-a-m {
      [dir='ltr'] & {
        border-right: 0.1rem solid rgba(var(--skin-color-1), 1);
        &:first-child {
          border-radius: 0.6rem 0 0 0.6rem;
        }
        &:last-child {
          border-radius: 0 0.6rem 0.6rem 0;
        }
      }

      [dir='rtl'] & {
        border-left: 0.1rem solid rgba(var(--skin-color-1), 1);
        &:last-child {
          border-radius: 0.6rem 0 0 0.6rem;
        }
        &:first-child {
          border-radius: 0 0.6rem 0.6rem 0;
        }
      }

      > span {
        font-size: 2.2rem;
      }

      > i {
        font-size: 1rem;
        padding-bottom: 0.3rem;
      }
    }

    .navigation-holder {
      height: 4rem;
      border-top: 0.1rem solid transparent;
      border-bottom: 0.1rem solid transparent;
      border-left: 0.3rem solid transparent;
      border-right: 0.3rem solid transparent;
    }

    ///////
    .lobby-w-right-column {
      grid-area: unset;
    }

    .l-r-col-ins {
      width: 100%;
    }

    .l-r-col-ins-holder {
      border-radius: 0.6rem;
    }

    .title-p-top {
      padding-right: 0.6rem;
    }

    .left-col-top-ins-right {
      border-radius: 0.6rem;
    }

    .top-r-sq {
      width: 3rem;
      height: 3rem;

      > i {
        font-size: 2.6rem;
      }
    }

    .l-c-m-ins {
      height: 3.4rem;
      margin-top: 0.2rem;
    }

    .l-c-m-ins-col {
      padding-right: 0.6rem;

      &:last-child {
        padding-right: 0;
      }
    }

    .l-c-m-ins-col-p {
      font-size: 1.2rem;
    }

    .left-col-g-row {
      height: 3.8rem;
      border-radius: 0.6rem;
      margin-bottom: 0.2rem;
    }

    .left-col-g-row-in {
      padding: 0 1.2rem;
    }

    .left-col-g-row-in-p {
      font-size: 1.2rem;
    }

    //////
  }

  &.mobile {
    font-size: 3.125vmin;

    // new help section START
    .game-help-new-section {
      display: grid;
      grid-template-rows: auto 11fr;

      .help-h-title {
        position: static;
        z-index: 9999;
      }

      .scroll-container {
        padding: 1rem;
      }
    }

    .game-help-info-row {
      h3 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }

    .game-help-table-container {
      padding-bottom: 0.2rem;

      h3 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
      }
    }

    .game-help-table-head {
      width: 100%;
      max-width: 100%;
      margin-bottom: 0.5rem;
      font-size: 1.2rem;
    }

    .game-help-table-content {
      max-width: 100%;
      width: 100%;
      border-radius: 0.6rem;
    }

    .game-help-table-row {
      height: 3rem;
    }

    .game-help-table-column {
      > span {
        font-size: 1.2rem;
      }
    }

    // new help section END
    // rebet START
    .rebet-popup-content {
      padding: 1rem;
      border-radius: 0.6rem;

      > i {
        font-size: 4.8rem;
        margin-bottom: 1.5rem;
      }

      > p {
        font-size: 1.4rem;
        margin-bottom: 1.9rem;
        line-height: 1.9rem;
      }
    }

    .rebet-popup-c-buttons {
      grid-column-gap: 1rem;

      > button {
        height: 3.8rem;
        font-size: 1.2rem;
        border-radius: 0.6rem;
      }
    }

    .rebet-popup-show-again {
      > label {
        font-size: 1.2rem;
        line-height: 1.6rem;
        padding-left: 2.4rem;

        &:before {
          left: -0.3rem;
          top: -0.4rem;
          font: 2.4rem icomoon
        }

        &:after {
          width: 1.6rem;
          height: 1.6rem;
          border-radius: 0.2rem;
        }
      }
    }

    //rebet END
    .bet-history-status {
      margin-top: 1.2rem;
      font-size: 1.8rem;
    }

    .notification-holder-b {
      top: 1rem;
    }

    .notification-container {
      padding: 1rem;
      border-radius: 0.6rem;
      margin-bottom: 0.5rem;

      > p {
        font-size: 1rem;
        line-height: 1.8rem;
      }

      > span {
        width: 2rem;
        height: 2rem;
        font-size: 2.4rem;
        [dir='ltr'] & {
          margin-left: 1rem;
        }

        [dir='rtl'] & {
          margin-right: 1rem;
        }
      }
    }

    .history-h-icon {
      font-size: 2.4rem;
    }

    .history-h-text {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

    .date-picker {
      border-radius: 0.6rem;
      margin-bottom: 0.2rem;
      margin-top: 1rem;
      min-height: auto;
    }

    .react-datepicker__header {
      padding-top: 0.8rem;
    }

    .react-datepicker__navigation--previous {
      left: 3rem;
    }

    .react-datepicker__navigation {
      display: block;
      line-height: 2rem;
      width: 0.8rem;
      height: 0.8rem;
      top: 1rem;
      border: none;
      background: rgba(var(--text-color), 0.85);
      clip-path: polygon(100% 0, 0 50%, 100% 100%);
      z-index: 100;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2rem;
      line-height: 3rem;
      font-size: 1.2rem;
    }

    .react-datepicker__current-month {
      margin-bottom: 1rem;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-size: 1.2rem;
    }

    .react-datepicker__navigation--next {
      right: 3rem;
      transform: rotate(180deg);
    }

    .search-cnt {
      height: 4rem;
      border-radius: 0.6rem;

      > input {
        padding: 0 0.6rem;
        font-size: 1.3rem;
      }

      > i {
        margin: 0 0.4rem;
        font-size: 2.6rem;
      }
    }

    .date-picker-sort-columns {
      margin: 1rem 0;
    }

    .date-picker-sort-c {
      font-size: 1.4rem;

      &.center {
        margin: 0 0.5rem;
      }
    }

    .date-picker-sort-content {
      height: 3.8rem;
      border-radius: 0.6rem;
    }

    .date-picker-sort-icon {
      width: 3rem;
      font-size: 2.8rem;
    }

    .date-picker-sort-numbers {
      width: 3rem;
      font-size: 1rem;
      padding: 0 0.2rem;
      min-width: 3rem;
    }

    .real-play-btn {
      height: 2.2rem;
      border-radius: 0.6rem;
      width: 11.6rem;
      font-size: 1rem;
    }

    .user-config-dropdown {
      top: 4.8rem;
      width: 14rem;
      padding: 1rem 1.2rem;
      border-radius: 0.4rem;
      left: auto;
      right: 0.4rem;
      &:before{
        left: auto;
        right: 1.5rem;
      }
    }

    .user-config-d-r-column {
      > span {
        font-size: 1rem;
        line-height: 1.6rem;
      }

      > i {
        width: 1.6rem;
        height: 1.6rem;
        font-size: 2rem;
        line-height: 2.2rem;
      }
    }

    .user-config-d-row {
      padding-bottom: 0.6rem;
      margin-bottom: 0.6rem;
      border-bottom: 0.1rem solid rgba(var(--skin-color-1), 1);

      &:last-child {
        padding: 0;
        margin: 0;
        border: 0;
      }
    }

    .user-config-title {
      font-size: 1rem;
      line-height: 1.6rem;
      margin-bottom: 0.4rem;
    }

    .switcher {
      width: 2.8rem;
      height: 1.6rem;
      border-radius: 0.9rem;

      &:before {
        width: 1.6rem;
        height: 1.6rem;
      }

      &.active {
        &:before {
          transform: translate(1.2rem, 0);
        }
      }
    }

    .timer-container {
      height: 3.6rem;
      font-size: 2rem;

      > span {
        line-height: 3.6rem;
      }
    }

    .type-btn {
      width: 5.4rem;
      height: 2.2rem;
      border-radius: 0.6rem;
      font-size: 1.4rem;

      &:before {
        width: 0.7rem;
        left: 1rem;
      }

      &:after {
        width: 0.2rem;
        left: 2.1rem;
      }
    }

    .mobile-wrapper {
      &.game-page {
        .m-scroll-b {
          overflow: unset;
        }

        .bet-type-holder {
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
        }

        .game-results-row {
          &:first-child {
            padding-top: 2.4rem;
          }
        }
      }

      &.lastResult {
        .game-results-container {
          &:before {
            transform: translate(-50%, -72%);
          }
        }
      }

      .lobby-w-right-column {
        grid-area: content-m;
      }

      .l-r-col-ins {
        width: 100%;
      }

      .game-results-r-inner {
        padding-top: 2.4rem;
      }

      .st-tabs-container {
        border-bottom: 0.1rem solid rgba(var(--skin-color-1), 1);
      }

      .st-tub-v {
        &:first-child {
          border-right: 0.1rem solid rgba(var(--skin-color-1), 1);
        }
      }

      .game-results-container {
        padding-top: 0;
        display: grid;
        grid-template-columns: 12fr;
        grid-template-rows: 4fr 8fr;
        justify-content: center;

        &:before {
          width: 90rem;
          height: 90rem;
          transform: translate(-50%, -75%);
        }
      }

      .game-results-row {
        margin-bottom: 0;

        &:last-child {
          justify-content: flex-end;
          padding-bottom: 1rem;
          margin-bottom: 0;
          height: 100%;
          //z-index: 0;
        }
      }

      .choose-number-t-holder {
        &:before {
          width: 90rem;
          height: 90rem;
          transform: translate(-50%, -70%);
        }
      }

      .bet-history-m-c-inner {
        &:before {
          width: 90rem;
          height: 90rem;
          transform: translate(-50%, -74%);
        }
      }

      .game-results-content {
        width: 250px;
      }

      .live-bets-cnt {
        padding: 0 0.3rem;
      }

      .game-results-img {
        width: 10rem;
        margin-bottom: 2rem;
      }

      .game-results-text {
        font-size: 2.2rem;
        line-height: 2.6rem;
        margin-bottom: 0.2rem;
      }

      .game-results-time {
        font-size: 1.4rem;
      }

      .ch-numbers-b {
        justify-content: unset;
        padding-top: 7vmin;
      }

      .n-type-holder,
      .info-i-holder {
        color: rgba(var(--text-color), 1);
        padding: 0 0.6rem 0 0.6rem;
        font-size: 2.8rem;
      }

      .user-n {
        font-size: 1rem;
        line-height: 1.4rem;
        padding: 0 0.5rem 0 0;
      }

      .four-chance-cnt {
        width: 32rem;

        .four-chance-row {
          width: 320px;

          .single-ch-number {
            width: 100%;
            height: 100%;
          }
        }
      }

      .game-container {
        border: 0;
      }

      .game-items-holder {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0.6rem;
        border-bottom-right-radius: 0.6rem;

        &.brazilian-view {
          .g-item-numbers {
            font-size: 0.7rem;
            padding: 0.4rem 0.5rem 0.4rem 0;

            > span {
              margin: 0.1rem 0;
              line-height: 0.7rem;
            }
          }

          .g-item {
            > img {
              bottom: 0.8rem;
              left: -0.6rem;
            }
          }

          .animal-number,
          .animal-name {
            font-size: 0.7rem;
          }

          .animal-name {
            bottom: 0.2rem;
          }

          .animal-number {
            left: 0.5rem;
            top: 0.4rem;
          }
        }
      }

      .game-help-row {
        min-height: 14rem;
      }

      .action-b-container {
        border: 0;
        margin-top: 0.4rem;
        margin-bottom: 0.8rem;
      }

      .action-b-grid {
        grid-template-columns: 10fr 14rem;
      }

      .single-ch-number {
        width: 4.6rem;
        height: 4.6rem;
        font-size: 1.8rem;
        margin: 0 0.5rem;
        border: 0.1rem solid rgba(var(--hero-color), 1);
      }

      .ch-title-text-r {
        padding-top: 2rem;
      }

      .ch-title-info {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }

      .ch-numbers-img {
        width: 9.6rem;
      }

      .money-v {
        font-size: 3.8rem;
      }

      .win-text {
        font-size: 1.8rem;
        line-height: 1.8rem;
      }

      .help-h-item {
        justify-content: center;
      }

      .game-help-container {
        padding: 0;
        position: absolute;
        left: 0;
        top: 0;
      }

      .game-help-r-info {
        padding: 0 1.6rem;
      }

      .user-config-dropdown {
        [dir='ltr'] & {
          left: auto;
          right: 0.4rem;
          &:before {
            left: auto;
            right: 2rem;
          }
        }

        [dir='rtl'] & {
          right: auto;
          left: 0.4rem;
          &:before {
            right: auto;
            left: 2rem;
          }
        }
      }

      .scroll-b-holder {
        border: 0;
      }

      .history-head {
        height: 3.8rem;
        border-bottom: 0.1rem solid rgba(var(--skin-color-1), 1);
        min-height: auto;
        width: calc(100% + 0.6rem);
        left: -0.3rem;
      }

      .history-h-column {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &.icon {
          width: 4rem;
          border-right: 0.1rem solid rgba(var(--skin-color-1), 1);
          border-left: 0.1rem solid rgba(var(--skin-color-1), 1);
        }
      }

      .history-date-text {
        margin-top: 0.8rem;
      }

      .sticky-container {
        padding: 0;
      }

      .help-h-title,
      .b-type-title {
        height: 3.8rem;
        background: rgba(var(--skin-color-2), 1);
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        border-bottom: 0.1rem solid rgba(var(--skin-color-1), 1);

        > i {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 4rem;
          border-right: 0.1rem solid rgba(var(--skin-color-1), 1);
          border-left: 0.1rem solid rgba(var(--skin-color-1), 1);
          height: 100%;
        }
      }

      .help-h-title {
        > i {
          font-size: 2.8rem;
        }
      }

      .game-help-r-empty {
        min-height: 14.2rem;
        margin-top: 1rem;
      }

      .bet-type-holder {
        .bet-history-m-head {
          width: calc(100% + 0.6rem);
          margin-left: -0.3rem;

          & + .type-grid {
            margin-top: 0.2rem;
          }
        }
      }

      .spinner {
        width: 2.6rem;
        height: 2.6rem;
      }

      .sticky-container {
        & + .result-filters-changes-row {
          margin-top: 0.2rem;
        }
      }

      .history-date-picker-container {
        &.open {
          max-height: 60rem;
        }
      }
    }

    .bet-action-info {
      [dir='ltr'] & {
        border-radius: 0.6rem 0 0 0.6rem;
      }

      [dir='rtl'] & {
        border-radius: 0 0.6rem 0.6rem 0;
      }

      > span {
        font-size: 2.8rem;
        margin-bottom: 0.5rem;
      }

      > p {
        font-size: 1rem;
        line-height: 1.4rem;
      }
    }

    .numbers-ch-title {
      padding-bottom: 0.8rem;
      font-size: 1rem;
      line-height: 1.6rem;
    }

    .ch-numbers-box {
      padding-bottom: 6rem;
    }

    .action-b-grid {
      height: 5.6rem;
      border-radius: 0.6rem;
      border: 0.1rem solid rgba(var(--skin-color-1), 1);
      grid-gap: 0.1rem;
    }

    .action-mini-grid {
      grid-gap: 0.1rem;
    }

    .action-btn {
      font-size: 1rem;
      line-height: 2rem;

      &.rebet {
        > i {
          font-size: 2.4rem;
        }
      }

      &.size-b {
        font-size: 1.8rem;
      }
    }

    .bet-prise-inp {
      font-size: 1.8rem;
      line-height: 2rem;
    }

    .result-filters-changes-row {
      height: 3.8rem;
      margin-bottom: 0.2rem;
    }

    .result-filters-c-column {
      font-size: 1rem;

      &:first-child {
        max-width: 6.4rem;
      }
    }

    .mini-animals-row {
      margin: 0 0.4rem;
    }

    .mini-animals-img {
      max-width: 1.6rem;
      max-height: 1.6rem;
      margin: 0 0.1rem;
    }

    .st-grid-holder {
      grid-row-gap: 0.1rem;
    }

    .st-tabs-container {
      height: 3.2rem;
    }

    .st-tub-v {
      line-height: 1.4rem;
      font-size: 1rem;
      padding: 0 0.4rem;
    }

    .st-tab-content {
      padding: 0;
    }

    .table-title-row {
      height: 2.6rem;
      padding-bottom: 0.4rem;

      > p {
        padding: 0 0.5rem;
        font-size: 1rem;
        line-height: 1.4rem;
        [dir='ltr'] & {
          &:first-child {
            padding-left: 1rem;
          }
          &:last-child {
            padding-right: 1rem;
          }
        }

        [dir='rtl'] & {
          &:first-child {
            padding-right: 1rem;
          }
          &:last-child {
            padding-left: 1rem;
          }
        }
      }
    }

    .st-content-info {
      height: 3.8rem;
      margin-bottom: 0.2rem;
      border-radius: 0.6rem;

      > p {
        padding: 0 0.5rem;
        font-size: 1rem;
        line-height: 1.4rem;
        [dir='ltr'] & {
          &:first-child {
            padding-left: 1rem;
          }

          &:last-child {
            padding-right: 1rem;
          }
        }

        [dir='rtl'] & {
          &:first-child {
            padding-right: 1rem;
          }

          &:last-child {
            padding-left: 1rem;
          }
        }
      }
    }

    .win-cnt-comp {
      min-width: 7.5rem;
      border-radius: 0.6rem;
      padding: 0 0.8rem;

      &:before {
        width: 0.7rem;
        left: 1rem;
      }

      &:after {
        width: 0.2rem;
        left: 2.1rem;
      }
    }

    .help-h-title {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

    .help-h-item {
      height: 3.8rem;
      border-radius: 0.6rem;
      padding: 0 1rem;
      line-height: 1.4rem;
      margin-bottom: 0.2rem;
      font-size: 1rem;
    }

    .game-h-inner {
      padding: 0 0.3rem;
    }

    .game-help-r-empty {
      border-radius: 0.6rem;
    }

    .game-help-r-i-text {
      font-size: 1rem;
      line-height: 1.6rem;
    }

    .type-grid {
      height: 3.8rem;
      border-radius: 0.6rem;
      grid-column-gap: 0.1rem;
      margin-bottom: 0.2rem;
      grid-template-columns: auto 3.8rem;
    }

    .bet-btn {
      font-size: 1.5rem;
    }

    .history-content {
      margin-bottom: 1.4rem;
    }

    .history-c-title {
      margin-bottom: 0.8rem;
      font-size: 1rem;
      line-height: 1.4rem;
      padding: 0 0.6rem;
    }

    .history-c-row {
      height: 3.8rem;
      border-radius: 0.6rem;
      font-size: 1rem;
      line-height: 1.4rem;
      padding: 0;

      > span {
        display: flex;
        align-items: center;
        padding-left: 1rem;
        height: 100%;
        position: relative;
      }

      .circle {
        width: 0.5rem;
        height: 0.5rem;
        right: 1rem;
        border: solid 0.1rem rgba(var(--skin-color-2), 1);
      }

      > button {
        width: 3.1rem;
      }
    }

    .result-r-cnt-m {
      padding: 1rem 0.3rem;
    }

    .result-r-h {
      font-size: 1.8rem;
      margin-bottom: 1rem;
      line-height: 2rem;
    }

    .result-r-row {
      height: 3.2rem;
    }

    .result-column {
      padding: 0 0.5rem;
      border-right: 0.1rem solid rgba(var(--skin-color-1), 1);

      > i {
        font-size: 1rem;
        margin: 0 0.4rem;
      }
    }
  }
}

#root {
  display: block;
}

.mobile-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: "header-m" "content-m" "navigation-m";
  grid-template-rows: auto 10fr auto;
  user-select: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;

  &:before {
    content: "\e909";
    position: absolute;
    display: none;
    opacity: 0;
    width: 8rem;
    height: 8rem;
    font: 8rem icomoon;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    animation-delay: 100ms;
    animation: 2200ms landscape-rotate ease-in-out forwards infinite;
    z-index: 99;
    transform: rotate(90deg);
  }
}

.m-scroll-holder {
  width: 100%;
  height: 100%;
  position: relative;
}

.m-scroll-b {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.m-header-row {
  width: 100%;
  grid-area: header-m;
  z-index: 9999;
  position: relative;

}

.m-content-row {
  width: 100%;
  grid-area: content-m;
  display: flex;
  background-color: rgba(var(--skin-color-2), 1);
}

.navigation-row {
  width: 100%;
  grid-area: navigation-m;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 7rem;
    left: 0;
    bottom: 4rem;
    z-index: 99;
    background: linear-gradient(to bottom, rgba(var(--skin-color-2), 0) 0%, rgba(var(--skin-color-1), 0.65) 100%);
    user-select: none;
    pointer-events: none;
  }
}




































