.game-grid-holder {
  width: 100%;
  height: 100%;
  position: relative;

  .last-result-container {
    margin-bottom: 2px;
    padding: 0;
  }

  .last-r-title-time {
    font-size: 10px;
  }

  .row-number-holder {
    font-size: 18px;
    color: rgba(var(--text-color), 1);
  }

  .single-res-row {
    margin-bottom: 2px;
  }

  .numbers-type-row {
    margin-top: 0;
    height: 46px;
    border: none;
    padding: 4px 0 4px 4px;
    border-radius: 40px;
  }

  .ball {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-right: 4px;
  }
}

.g-grid-container {
  display: grid;
  grid-template-areas: "game-logo" "game-board" "game-actions";
  grid-template-rows: auto 10fr auto;
  width: 100%;
  height: 100%;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(var(--skin-color-1), 0.8);
    z-index: 99;
    display: none;
  }

  &.disabled-type-1 {
    &:before {
      display: block;
    }

    .ch-title-text-r,
    .bet-amount-row {
      opacity: 0.2;
    }

    .game-action-row,
    .game-table-row {
      z-index: 999;
    }

    .game-items-holder {
      box-shadow: inset 0 0 0 1px #ffbb00;
    }

    .g-item {
      box-shadow: inset 0 0 0 1px #ffbb00;
    }

    .ch-numbers-b {
      transform: scale(1.2);
    }
  }
}

.ch-numbers-b-fixed {
  pointer-events: none;
}

.game-logo-row {
  width: 100%;
  grid-area: game-logo;
  position: relative;
}

.game-back-icon {
  position: absolute;
  display: block;
  left: 10px;
  top: 8px;
  font-size: 35px;
  color: rgba(var(--text-color), 1);
  cursor: pointer;
  z-index: 9999;

  & + .g-logo-holder {
    padding: 0 30px;
  }
}

.g-logo-holder {
  width: 100%;
  display: flex;
  align-items: center;
  height: 58px;
  justify-content: center;

  > img {
    max-height: 28px;
    max-width: 100%;
    z-index: 99;
  }
}

.game-table-row {
  width: 100%;
  height: 100%;
  grid-area: game-board;
  position: relative;
  transform: translate(0, 0);
  transition: transform 230ms;
}

.game-container {
  width: 100%;
  height: 100%;
  position: relative;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.game-items-holder {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18%, 1fr));
  border-radius: 6px;
  background-color: rgba(var(--skin-color-1), 1);
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(var(--skin-color-1), 1) inset;
  border: 1px solid transparent;
  transition: transform 240ms;
  grid-gap: 1px;
  [dir='rtl'] & {
   direction: ltr;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000000, 0.2);
    opacity: 0;
    user-select: none;
    pointer-events: none;
    transition: opacity 240ms linear;
    z-index: 99;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &.brazilian-view {
    .g-item-numbers {
      width: auto;
      height: 100%;
      flex-direction: column;
      justify-content: flex-start;
      font-size: 14px;
      padding: 5px 6px 5px 0;
    }
    .g-item {
      justify-content: flex-end;

      > img {
        bottom: 8px;
        left: -26px;
        max-height: 66%;
      }
    }
    .animal-number,
    .animal-name {
      display: block;
    }
  }
}

.g-item {
  //background: url("../images/Animals/1.png") no-repeat 50% 0 / auto 80%;
  display: flex;
  background-image: linear-gradient(
                  to bottom,
                  rgba(0, 55, 84, 0),
                  rgba(var(--skin-color-2), 1) 67%
  );
  background-color: rgba(var(--skin-color-3), 0.6);
  align-items: flex-end;
  position: relative;
  cursor: pointer;
  transition: border-color, background-color 240ms;

  > img {
    display: block;
    transform: translate3d(0, 0, 0);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    //bottom: 1.5vw;
    bottom: 22px;
    margin: auto;
    max-width: 100%;
    max-height: 70%;
  }

  &:hover,
  &.active {
    box-shadow: inset 0 0 0 1px #ffbb00;
    z-index: 999;
    background-color: rgba(var(--skin-color-3), 0.6);
  }
}

.g-item-border {
  box-shadow: inset 0 0 0 1px #ffbb00;
  z-index: 999;
  background-color: rgba(var(--skin-color-3), 0.6);
}

.g-item-numbers {
  width: 100%;
  padding: 5px 0;
  //font-size: 1vw;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    margin: 0 2px;
  }
}

.animal-number {
  position: absolute;
  left: 7px;
  top: 9px;
  color: rgba(var(--text-color), 0.85);
  font-size: 12px;
  line-height: 14px;
  display: none;
}

.animal-name {
  display: none;
  position: absolute;
  width: 100%;
  bottom: 5px;
  left: 0;
  text-align: center;
  color: rgba(var(--text-color), 0.85);
  font-size: 12px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 2px;
}

.g-item-numbers-active {
  color: rgba(var(--brand-color-2-3), 1);
}

.game-action-row {
  width: 100%;
  grid-area: game-actions;
}

.action-b-container {
  width: 100%;
  border: 10px solid transparent;
  position: relative;
}

.action-b-grid {
  width: 100%;
  height: 66px;
  background-color: rgba(var(--skin-color-1), 1);
  border-radius: 6px;
  border: 1px solid rgba(var(--skin-color-1), 1);
  display: grid;
  grid-template-columns: 9fr 190px;
  grid-gap: 1px;
}

.left-col-action {
  position: relative;
  height: 100%;
  overflow: hidden;
}

// teminal Numpad START
.terminal-numpad{
  display: grid;
  grid-template-columns: 24px 10fr 203px;
  width: 100%;
  height: 66px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 6px;
  overflow: hidden;
  background-color: rgba(var(--skin-color-1), 1);
  grid-gap: 1px;
  transform: translate(-120%, 0);
  transition: transform 230ms;
  &.active{
    transform: translate(0, 0);
  }
}
.terminal-n-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 12px;
  background-color: rgba(var(--hero-color), 1);
  color: rgba(var(--hero-color-set), 1);
  cursor: pointer;
}
.terminal-n-content {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1px;
  .item {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.c-1 {
      background-color: rgba(var(--hero-color), 0.65);
    }
    &.c-3 {
      background-color: rgba(var(--hero-color), 0.45);
      color: rgba(var(--hero-color-set), 0.65);
    }
    &.c-4 {
      background-color: rgba(var(--hero-color), 0.85);
    }
    &.full{
      grid-column: span 2;
    }
  }
}
.i-bet-act {
  width: 100%;
  height: 100%;
  display: grid;
  grid-row-gap: 1px;
  grid-template-rows: 6fr  6fr;
  input {
    outline: none;
    border: none;
    background-color: rgba(var(--skin-color-2), 1);
    color:  rgba(var(--text-color), 1);
    width: 100%;
    text-align: center;
    font-size: 18px;
    &::placeholder{
      color:  rgba(var(--text-color), 1);
    }
  }
  > button {
    width: 100%;
    border-radius: 0;
  }
}
// teminal Numpad END

//action info start-------------
.bet-action-info {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(var(--skin-color-2), 1);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgba(var(--brand-color-2-3), 1);
  animation: bet-action-info 240ms linear;
  [dir='ltr'] & {
    border-radius: 6px 0 0 6px;
  }

  [dir='rtl'] & {
    border-radius: 0 6px 6px 0;
  }

  > span {
    display: inline-block;
    vertical-align: top;
    font-size: 28px;
    margin-bottom: 5px;
  }

  > p {
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
  }
}

@keyframes bet-action-info {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

//action info END---------------
.action-mini-grid {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(4, 1fr);
}

.price-input-box {
  grid-column: 2/4;
  position: relative;
}

.bet-prise-inp {
  width: 100%;
  height: 100%;
  font-family: inherit;
  border: none;
  background-color: rgba(var(--skin-color-2), 1);
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: 0;
  color: rgba(var(--text-color), 1);

  &::-webkit-input-placeholder {
    color: rgba(var(--text-color), 1);
    opacity: 1;
  }

  &::-moz-placeholder {
    color: rgba(var(--text-color), 1);
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: rgba(var(--text-color), 1);
    opacity: 1;
  }

  &:-moz-placeholder {
    color: rgba(var(--text-color), 1);
    opacity: 1;
  }
}

.bet-btn {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: inherit;
  border: none;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  color: rgba(var(--brand-color-1-set), 1);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  [dir='ltr'] & {
    border-radius: 0 6px 6px 0;
  }

  [dir='rtl'] & {
    border-radius: 6px 0 0 6px;
  }
  &.green {
    background-image: linear-gradient(to bottom, rgba(var(--brand-color-1-1), 1), rgba(var(--brand-color-1), 1));
    background-color: transparent;

    &:hover {
      background: rgba(var(--brand-color-1-2), 1);
    }
  }

  //> span {
  //  z-index: 99;
  //  position: relative;
  //}
  &.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.4;
    cursor: default;
  }
}

.action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--hero-color), 0.65);
  color: rgba(var(--hero-color-set), 0.85);
  font-size: 10px;
  line-height: 20px;
  cursor: pointer;
  transition: background-color 240ms, color 240ms;
  text-transform: uppercase;
  [dir='ltr'] & {
    &:first-child {
      border-radius: 6px 0 0;
    }
    &:nth-child(4) {
      border-radius: 0 0 0 6px;
    }
  }

  [dir='rtl'] & {
    &:first-child {
      border-radius: 0 6px 0 0;
    }
    &:nth-child(4) {
      border-radius: 0 0 6px 0;
    }
  }

  &.size-b {
    font-size: 18px;
    line-height: 20px;
  }

  &:hover {
    background-color: rgba(var(--hero-color), 0.85);
  }

  &.disabled {
    pointer-events: none;
    background-color: rgba(var(--hero-color), 0.45);
    color: rgba(var(--hero-color-set), 0.45);
    cursor: default;
  }

  &.full {
    grid-column: span 2/4;
  }
  &.rebet{
    > i {
      font-size: 24px;
    }
  }
}


// bet history START
.bet-history-holder {
  //display: grid;
  //grid-template-rows: 2fr 2fr 8fr;
  //grid-template-columns: 12fr;
  //align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;

  .money-v {
    //font-size: 5vmin;
    font-size: 40px;
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    border-radius: 50%;
    width: 810px;
    height: 810px;
    top: 0;
    left: 50%;
    background-color: rgba(#000000, 0.1);
    transform: translate(-50%, -66%);
  }
}

.bet-history-h-row {
  width: 100%;

  &:last-child {
    margin-top: 60px;
  }
}

.bet-history-status {
  display: block;
  //font-size: 2.2vmin;
  font-size: 18px;
  font-weight: 900;
  //line-height: 2.5vmin;
  line-height: 24px;
  text-transform: capitalize;
  color: rgba(var(--text-color), 0.65);
  position: relative;

  &.status-3 {
    color: rgba(var(--status-color-3), 1);
  }

  &.status-2 {
    color: rgba(var(--status-color-2), 1);
  }

  &.status-1 {
    color: rgba(var(--status-color-1), 1);
  }

  &.no-status {
    color: rgba(0, 0, 0, 0) !important;
  }
}

.bet-history-count-info {
  display: inline-flex;
  vertical-align: top;
  margin: 20px auto;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: 900;
  height: 48px;
  background: rgba(var(--skin-color-5), 0.4);
  border-radius: 6px;
  min-width: 284px;
  padding: 0 20px;
  position: relative;
}

// bet history END
.img-bet-history-wrapper {
  width: 200px;
  color: white;
  position: relative;
  [dir='ltr'] & {
    left: 50%;
    transform: translateX(-50%)
  }

  [dir='rtl'] & {
    left: -50%;
    transform: translateX(50%)
  }
  &.ec {
    width: 360px;
  }
}

.img-bet-history path {
  fill: rgba(var(--skin-color-3), 1);
}

.slide-left {
  transform: translate3d(-110%, 0, 0);
}

.slide-right {
  transform: translate3d(110%, 0, 0);
}

.last-result-small-exit {
  opacity: 1;
}

.last-result-small-enter {
  opacity: 0;
  transform: scale(0.2);
}

.last-result-small-exit-active {
  opacity: 0;
  transform: scale(0.3);
  transition: all 0.3s;
}

.last-result-small-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.2s;
}

.choose-animal-anim-enter {
  transform: translate(140%, 0);
  -webkit-transform: translate(140%, 0);
  opacity: 0;
  transition: all 0.2s;
}

.choose-animal-anim-exit {
  opacity: 1;
  transition: all 0.2s;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);

}

.choose-animal-anim-enter-active {
  opacity: 1;
  transition: all 0.2s;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
}

.choose-animal-anim-exit-active {
  opacity: 0;
  transform: translate(-140%, 0);
  -webkit-transform: translate(-140%, 0);
  transition: all 0.2s;
}

.animals-table-enter {
  transform: translate3d(-110%, 0, 0);
  opacity: 0;
}

.animals-table-exit {
  transform: translate3d(0, 0, 0);

  display: none;
  opacity: 1;
}

.animals-table-enter-active {
  transform: translate3d(0, 0, 0);

  opacity: 1;
  transition: all 0.3s;
}

.animals-table-exit-active {
  opacity: 0;
  transform: translate3d(110%, 0, 0);

  transition: all 0.3s;
}

.new-result-main-enter {
  transform: translate3d(110%, 0, 0);
  opacity: 0;
}

.new-result-main-exit {
  transform: translate3d(0, 0, 0);

  opacity: 1;
}

.new-result-main-enter-active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: all 0.4s;
}

.new-result-main-mob-exit-active {
  opacity: 0;
  transform: translate3d(0, -110%, 0);
  transition: all 0.4s;
}

.new-result-main-mob-enter {
  transform: translate3d(0, -110%, 0);
  opacity: 0;
}

.new-result-main-mob-exit {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.new-result-main-mob-enter-active {
  transform: translate3d(0, 0, 0);

  opacity: 1;
  transition: all 0.4s;
}

.user-bet-mobile-exit-active {
  opacity: 0;
  transform: translate3d(0, -110%, 0);
  transition: all 0.4s;
}

.user-bet-mobile-enter {
  transform: translate3d(0, 110%, 0);

  opacity: 0;
}

.user-bet-mobile-exit {
  transform: translate3d(0, 0, 0);

  opacity: 1;
}

.user-bet-mobile-enter-active {
  transform: translate3d(0, 0, 0);

  opacity: 1;
  transition: all 0.4s;
}

.new-result-main-exit-active {
  opacity: 0;
  transform: translate3d(-110%, 0, 0);
  transition: all 0.4s;
}

.help-item-enter {
  transform: translate(110%, 0);
  opacity: 1;
}

.help-item-enter-active {
  opacity: 1;
  transition: all 0.3s;
  transform: translate(0, 0);
}

.help-item-exit {
  opacity: 1;
  transform: translate(0, 0);
}

.help-item-exit-active {
  opacity: 1;
  transform: translate(110%, 0);
  transition: all 0.3s;

}


.animated-element {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: transform 240ms linear;
}

.landscape-icon-cnt {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  > i {
    display: block;
    font-size: 28vmin;
    animation: land-icon 2s ease-in-out infinite;
  }
}

@keyframes land-icon {
  0% {
    transform: rotate(0);
    opacity: 0;
  }
  99% {
    transform: rotate(-180deg);
    opacity: 1;
  }
  100% {
    transform: rotate(-180deg);
    opacity: 0;
  }
}
