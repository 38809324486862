//Global styles start----------------------------------
@import "assets/styles/normalize";
@import "assets/styles/color-settings";
@import "assets/fonts/fonts";
//Global styles END----------------------------------

//WEB styles start----------------------------------
@import "assets/styles/web/web-structure";
@import "assets/styles/web/left-column";
@import "assets/styles/web/game-column";
@import "assets/styles/web/right-column";
@import "assets/styles/web/choose-number";
//WEB styles END------------------------------------

//Mobile styles start----------------------------------
@import "assets/styles/mobile/mobile-structure";
//Mobile styles END------------------------------------
@import "assets/styles/web/media";

.animation-off{
  * {
    animation: none !important;
    transition: none !important;
  }
  .win-cnt-comp {
    transform: translate(0, 0) !important;
  }
}


