//Font Start--------------------------------------------
$font-f: Roboto, Arial, Helvetica, sans-serif !default;
//Font END----------------------------------------------

@function set-color($color, $color-dark: #000000, $color-light: #ffffff) {
  @if (lightness($color) > 48) {
    @return $color-dark;
  } @else {
    @return $color-light;
  }
}

//Skin Colors Start-------------------------------------
$colors-list: (
        "global-color-1": #003754,
  //#003754
        "global-color-2": #ffffff,
        "global-color-3": #000000
) !default;

$hero-color: #0094c7 !default; //#0094c7
$brand-color-1: #00d924 !default; //#00d924
$brand-color-2: #fed700 !default; //#fed700

// brand colors sub Start
$brand-color-1-1: lighten(adjust-hue($brand-color-1, -41), 7.45) !default; //#84ff00
$brand-color-1-2: darken($brand-color-1, 4.12) !default; //#00d924
$brand-color-2-1: lighten(adjust-hue($brand-color-2, 10), 22.55) !default; //#fdff72
$brand-color-2-2: adjust-hue($brand-color-2, -20) !default; //#fe8200
$brand-color-2-3: lighten(adjust-hue($brand-color-2, -7), 0.2) !default; //#ffba00
// brand colors sub END


//Skin colors END---------------------------------------

//Status colors Start-----------------------------------
$status-color-1: #00c421 !default; //#00C421
$status-color-2: #c40000 !default; //#C40000
$status-color-3: #ffbb00 !default; //#ffbb00
//Status colors END-------------------------------------

//img filter Stat
$filter: invert(0) !default;
//img filter End

//background colors start--------------------------------------------------------------------------

$skin-color-black: #000000 !default;
$skin-color-white: #ffffff !default;

// Set colors START
$hero-color-set: set-color($hero-color) !default;
$brand-color-1-set: set-color($brand-color-1) !default;
$brand-color-2-set: set-color($brand-color-2) !default;
// Set colors END

@each $name, $global-color in $colors-list {
  @for $i from length($name) through 1 {
    $skin-color-1: darken(adjust-hue($global-color, 3), 7.45) !default; //#001C2E
    $skin-color-2: $global-color !default; //#003754
    $skin-color-3: lighten(adjust-hue($global-color, -2), 7.45) !default; //#00547A
    $skin-color-4: lighten(adjust-hue($global-color, -4), 12.16) !default; //#006A92
    $skin-color-5: lighten(adjust-hue($global-color, -5), 22.55) !default; //#0094c7
    $skin-color-6: lighten(#0094c7, 10.98) !default; //#00beff

    @if (lightness($global-color) > 45) {
      $skin-color-black: #ffffff;
      $skin-color-white: #000000;
      $filter: invert(1);
    }
    @if (lightness($global-color) > 95) {
      $skin-color-1: darken($global-color, 10);
      $skin-color-2: darken($global-color, 20);
      $skin-color-3: darken(saturate(adjust-hue($global-color, -7), 7.95), 13.33);
      $skin-color-4: darken(saturate(adjust-hue($global-color, -8), 5.28), 10.78);
      $skin-color-5: darken(desaturate(adjust-hue($global-color, -195), 100), 20.2);
      $skin-color-6: darken(desaturate(adjust-hue($global-color, -105), 100), 20.2);
    }
    @if (lightness($global-color) < 10) {
      $skin-color-1: $global-color;
      $skin-color-2: lighten(adjust-hue($global-color, -2), 6);
      $skin-color-3: lighten(adjust-hue($global-color, -2), 10);
    }
    //background colors END------------------------------------------------------------------

    .#{nth($name , $i)} {
      --font-f: #{$font-f};
      --global-color-red: #{red($global-color)};
      --global-color-green: #{green($global-color)};
      --global-color-blue: #{blue($global-color)};
      //
      --global-color: var(--global-color-red), var(--global-color-green), var(--global-color-blue);
      //
      --hero-color: #{red($hero-color), green($hero-color), blue($hero-color)};
      --brand-color-1: #{red($brand-color-1), green($brand-color-1), blue($brand-color-1)};
      --brand-color-1-1: #{red($brand-color-1-1), green($brand-color-1-1), blue($brand-color-1-1)};
      --brand-color-1-2: #{red($brand-color-1-2), green($brand-color-1-2), blue($brand-color-1-2)};
      --brand-color-2: #{red($brand-color-2), green($brand-color-2), blue($brand-color-2)};
      --brand-color-2-1: #{red($brand-color-2-1), green($brand-color-2-1), blue($brand-color-2-1)};
      --brand-color-2-2: #{red($brand-color-2-2), green($brand-color-2-2), blue($brand-color-2-2)};
      --brand-color-2-3: #{red($brand-color-2-3), green($brand-color-2-3), blue($brand-color-2-3)};
      //
      --global-text-color: calc(
              ((((var(--global-color-red) * 299) +
              (var(--global-color-green) * 587) +
              (var(--global-color-blue) * 114)) /
              1000) -
              128) *
              -1000
      );
      --text-color: var(--global-text-color), var(--global-text-color), var(--global-text-color);
      //
      --status-color-1: #{red($status-color-1), green($status-color-1), blue($status-color-1)};
      --status-color-2: #{red($status-color-2), green($status-color-2), blue($status-color-2)};
      --status-color-3: #{red($status-color-3), green($status-color-3), blue($status-color-3)};
      --skin-color-black: #{red($skin-color-black), green($skin-color-black),blue($skin-color-black)};
      --skin-color-white: #{red($skin-color-white), green($skin-color-white), blue($skin-color-white)};
      --skin-color-1: #{red($skin-color-1), green($skin-color-1), blue($skin-color-1)};
      --skin-color-2: #{red($skin-color-2), green($skin-color-2), blue($skin-color-2)};
      --skin-color-3: #{red($skin-color-3), green($skin-color-3), blue($skin-color-3)};
      --skin-color-4: #{red($skin-color-4), green($skin-color-4), blue($skin-color-4)};
      --skin-color-5: #{red($skin-color-5), green($skin-color-5), blue($skin-color-5)};
      --skin-color-6: #{red($skin-color-6), green($skin-color-6), blue($skin-color-6)};
      --filter: invert(0);

      // set colors START
      --hero-color-set: #{red($hero-color-set), green($hero-color-set), blue($hero-color-set)};
      --brand-color-1-set: #{red($brand-color-1-set), green($brand-color-1-set), blue($brand-color-1-set)};
      --brand-color-2-set: #{red($brand-color-2-set), green($brand-color-2-set),blue($brand-color-2-set)};
      // set colors END
    }
  }
}
