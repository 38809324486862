.maintance {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    display: block;
    width: 100%;
  }
}
