
@media  screen and (min-device-width: 539px)  and (orientation : portrait) {
  html{
    &.mobile{
      font-size: 2.2vmin;
    }
    &.lobby{
      font-size: 2vmin;
    }
  }
  .mobile-wrapper {
    .game-results-content{
      transform: scale(1.8) translate(0, -25%);
      //transform: scale(1.8);
    }
    .bet-history-m-c-inner {
      &:before{
        transform: translate(-50%, -80%) !important;
      }
    }
    .choose-number-t-holder {
      &:before{
        transform: translate(-50%, -68%) !important;
      }
    }
    .game-results-container{
      &:before{
        transform: translate(-50%, -73%) !important;
      }
    }
  }
}
@media screen and (min-device-width: 768px)  and (min-device-height: 1024px) {
  .mobile-wrapper{
    .game-results-content{
      transform: scale(1.8);
    }
  }
}
@media screen and (max-height: 737px) and (orientation : portrait) {
  html.mobile .mobile-wrapper.lastResult .game-results-container:before {
    transform: translate(-50%, -75%);
  }
  html.mobile .mobile-wrapper .game-results-container:before{
    transform: translate(-50%, -79%);
  }
  .mobile-wrapper {
    .ch-numbers-b{
      padding-top: 2rem;
    }
  }
  .bet-amount-row{
    padding-bottom: 10%;
  }
  .m-content-row{
    &.game{
      .choose-number-t-holder{
        transform: scale(0.9);
      }
    }
  }
  .game-results-content{
    transform: scale(0.9);
  }
}
@media screen and (max-width: 321px) {
  .mobile-wrapper {
    .ch-numbers-b{
      padding-top: 2rem;
    }
  }
  .bet-amount-row{
    padding-bottom: 10%;
  }
  .m-content-row{
    &.game{
      .choose-number-t-holder{
        transform: scale(0.8);
      }
    }
  }
  .game-results-content{
    transform: scale(0.78);
  }
}
@media screen and (max-width: 980px){
  html {
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
  }
}
@media screen and (orientation: landscape) {
 .mobile-wrapper {
   &:before{
     display: block;
   }
   > div {
     display: none;
   }
 }
}
