.spinner {
    width: 30px;
    height: 30px;
    border: 1.5px solid #FFF;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto;
    z-index: 999;
    position: relative;

}
.animation-off .spinner{
    animation: spin 1s linear infinite !important;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
