//@import 'styles/constants';
$version-control: '?v=#{random(888)}';
@font-face {
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  src: local('mardoto-regular'),
  url('arm/mardoto-regular-webfont.woff2#{$version-control}') format('woff2'),
  url('arm/mardoto-regular-webfont.woff#{$version-control}') format('woff'),
  url('arm/mardoto-regular-webfont.ttf#{$version-control}') format('truetype');
  unicode-range: U+0530-058F;
  font-display: fallback;
}
@font-face {
  font-family: "";
}
@font-face {
  font-family: Roboto;
  font-weight: 600;
  font-style: normal;
  src: local('mardoto-bold'),
  url('arm/mardoto-bold-webfont.woff2#{$version-control}') format('woff2'),
  url('arm/mardoto-bold-webfont.woff#{$version-control}') format('woff'),
  url('arm/mardoto-bold-webfont.ttf#{$version-control}') format('truetype');
  unicode-range: U+0530-058F;
  font-display: fallback;
}
@font-face {
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  src: local('Roboto'),
  url('default/Roboto.woff2#{$version-control}') format('woff2'),
  url('default/Roboto.woff#{$version-control}') format('woff'),
  url('default/Roboto.ttf#{$version-control}') format('truetype'),
  url('default/Roboto.eot#{$version-control}'),
  url('default/Roboto.eot#{$version-control}') format('embedded-opentype'),
  url('default/Roboto.svg#{$version-control}') format('svg');
  font-display: fallback;
}
@font-face {
  font-family: Roboto;
  font-weight: 600;
  font-style: normal;
  src: local('Roboto Bold'),
  url('default/RobotoBold.woff2#{$version-control}') format('woff2'),
  url('default/RobotoBold.woff#{$version-control}') format('woff'),
  url('default/RobotoBold.ttf#{$version-control}') format('truetype'),
  url('default/RobotoBold.eot#{$version-control}'),
  url('default/RobotoBold.eot#{$version-control}') format('embedded-opentype'),
  url('default/RobotoBold.svg#{$version-control}') format('svg');
  font-display: fallback;
}
@font-face {
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  src: local('IRAN_SemiBold'),
  url('farsi/IRAN_SemiBold.woff2#{$version-control}') format('woff2'),
  url('farsi/IRAN_SemiBold.woff#{$version-control}') format('woff'),
  url('farsi/IRAN_SemiBold.ttf#{$version-control}') format('truetype'),
  url('farsi/IRAN_SemiBold.eot#{$version-control}');
  unicode-range: U+0600-06FF;
  font-display: fallback;
}
@font-face {
  font-family: Roboto;
  font-weight: 600;
  font-style: normal;
  src: local('IRAN_SemiBold'),
  url('farsi/IRAN_SemiBold.woff2#{$version-control}') format('woff2'),
  url('farsi/IRAN_SemiBold.woff#{$version-control}') format('woff'),
  url('farsi/IRAN_SemiBold.ttf#{$version-control}') format('truetype'),
  url('farsi/IRAN_SemiBold.eot#{$version-control}');
  unicode-range: U+0600-06FF;
  font-display: fallback;
}

@font-face {
  font-family: Roboto;
  font-weight: 900;
  font-style: normal;
  src: local('FjallaOne-Regular');
  src: url('numbers/FjallaOne-Regular.eot?#{$version-control}') format('embedded-opentype'),
  url('numbers/FjallaOne-Regular.woff#{$version-control}') format('woff'),
  url('numbers/FjallaOne-Regular.ttf#{$version-control}')   format('truetype'),
  url('numbers/FjallaOne-Regular.svg#FjallaOne-Regular#{$version-control}') format('svg');
  font-display: fallback;
}


@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  src: local('Swiss721BT-BlackCondensed');
  src: url('numbers/Swiss721BT-BlackCondensed.eot#{$version-control}') format('embedded-opentype'),
  url('numbers/Swiss721BT-BlackCondensed.woff#{$version-control}') format('woff'),
  url('numbers/Swiss721BT-BlackCondensed.ttf#{$version-control}')  format('truetype'),
  url('numbers/Swiss721BT-BlackCondensed.svg#Swiss721BT-BlackCondensed#{$version-control}') format('svg');
  font-display: fallback;
}

@font-face {
  font-family: Roboto;
  font-weight: 800;
  font-style: normal;
  src: local('Oswald-Stencil');
  src: url('numbers/Oswald-Stencil.eot#{$version-control}') format('embedded-opentype'),
  url('numbers/Oswald-Stencil.woff#{$version-control}') format('woff'),
  url('numbers/Oswald-Stencil.ttf#{$version-control}')  format('truetype'),
  url('numbers/Oswald-Stencil.svg#Oswald-Stencil#{$version-control}') format('svg');
  font-display: fallback;
}







//
//
//@font-face {
//  font-family: 'Poppins';
//  font-weight: 300;
//  font-style: normal;
//  src: local('PoppinsLight'),
//  url('default/PoppinsLight.eot#{$version-control}'),
//  url('default/PoppinsLight.eot#{$version-control}') format('embedded-opentype'),
//  url('default/PoppinsLight.woff2#{$version-control}') format('woff2'),
//  url('default/PoppinsLight.woff#{$version-control}') format('woff'),
//  url('default/PoppinsLight.ttf#{$version-control}') format('truetype'),
//  url('default/PoppinsLight.svg#{$version-control}') format('svg');
//  font-display: fallback;
//}
//@font-face {
//  font-family: 'Poppins';
//  font-weight: 400;
//  font-style: normal;
//  src: local('PoppinsMedium'),
//  url('default/PoppinsMedium.eot#{$version-control}'),
//  url('default/PoppinsMedium.eot#{$version-control}') format('embedded-opentype'),
//  url('default/PoppinsMedium.woff2#{$version-control}') format('woff2'),
//  url('default/PoppinsMedium.woff#{$version-control}') format('woff'),
//  url('default/PoppinsMedium.ttf#{$version-control}') format('truetype'),
//  url('default/PoppinsMedium.svg#{$version-control}') format('svg');
//  font-display: fallback;
//}
//@font-face {
//  font-family: 'Poppins';
//  font-weight: 500;
//  font-style: normal;
//  src: local('PoppinsSemiBold'),
//  url('default/PoppinsSemiBold.eot#{$version-control}'),
//  url('default/PoppinsSemiBold.eot#{$version-control}') format('embedded-opentype'),
//  url('default/PoppinsSemiBold.woff2#{$version-control}') format('woff2'),
//  url('default/PoppinsSemiBold.woff#{$version-control}') format('woff'),
//  url('default/PoppinsSemiBold.ttf#{$version-control}') format('truetype'),
//  url('default/PoppinsSemiBold.svg#{$version-control}') format('svg');
//  font-display: fallback;
//}
//@font-face {
//  font-family: 'Poppins';
//  font-weight: 600;
//  font-style: normal;
//  src: local('PoppinsBold'),
//  url('default/PoppinsBold.eot#{$version-control}'),
//  url('default/PoppinsBold.eot#{$version-control}') format('embedded-opentype'),
//  url('default/PoppinsBold.woff2#{$version-control}') format('woff2'),
//  url('default/PoppinsBold.woff#{$version-control}') format('woff'),
//  url('default/PoppinsBold.ttf#{$version-control}') format('truetype'),
//  url('default/PoppinsBold.svg#{$version-control}') format('svg');
//  font-display: fallback;
//}
//@font-face {
//  font-family: 'Poppins';
//  font-weight: 800;
//  font-style: normal;
//  src: local('PoppinsExtraBold'),
//  url('default/PoppinsExtraBold.eot#{$version-control}'),
//  url('default/PoppinsExtraBold.eot#{$version-control}') format('embedded-opentype'),
//  url('default/PoppinsExtraBold.woff2#{$version-control}') format('woff2'),
//  url('default/PoppinsExtraBold.woff#{$version-control}') format('woff'),
//  url('default/PoppinsExtraBold.ttf#{$version-control}') format('truetype'),
//  url('default/PoppinsExtraBold.svg#{$version-control}') format('svg');
//  font-display: fallback;
//}
