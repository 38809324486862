//left column start-----------------------------------------
.l-col-grid {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: grid;
  grid-template-areas: "icon-tabs" "icon-tabs-list";
  grid-template-rows: auto 11fr;
  grid-row-gap: 8px;
}

.tab-content-row {
  width: 100%;
  background: rgba(var(--skin-color-2), 1);
  position: relative;
  height: 100%;
  border-radius: 6px;
  grid-area: icon-tabs-list;
}

.tabs-container {
  display: grid;
  width: 100%;
  grid-column-gap: 2px;
  grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
  height: 36px;
}

.single-tab-b {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(var(--skin-color-2), 1);
  width: 100%;
  cursor: pointer;
  color: rgba(var(--text-color), 0.45);
  transition: color 240ms, background-color, 240ms;
  font-size: 26px;

  [dir='ltr'] & {
    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  [dir='rtl'] & {
    &:first-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:last-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  &:hover {
    color: rgba(var(--text-color), 1);

    > span {
      transform: translate(0, -2px);
    }
  }

  &.active {
    background: rgba(var(--hero-color), 1);
    cursor: default;
    pointer-events: none;
    color: rgba(var(--hero-color-set), 1);

    > span {
      transform: translate(0, -2px);
    }
  }

  > span {
    transition: transform 240ms;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #c40000;
      right: 2px;
      top: 4px;
      display: none;
    }

    &.active {
      &:after {
        display: block;
      }
    }
  }
}

// help START
.help-holder {
  width: 100%;
}

.help-h-title {
  font-size: 16px;
  line-height: 19px;
  padding: 4px 10px 14px 10px;
  color: rgba(var(--text-color), 1);
  text-transform: capitalize;
  text-align: center;
}

.help-h-item {
  height: 38px;
  display: flex;
  align-items: center;
  width: 100%;
  background: rgba(var(--skin-color-3), 1);
  border-radius: 6px;
  margin-bottom: 2px;
  padding: 0 10px;
  font-size: 10px;
  text-transform: capitalize;
  line-height: 14px;
  color: rgba(var(--text-color), 0.65);
  transition: 230ms all;
  cursor: pointer;

  &:hover,
  &.active {
    background: rgba(var(--hero-color), 1);
    color: rgba(var(--hero-color-set), 1);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

// help END
//Last result start-------------------------
.last-result-container {
  width: 100%;
  padding: 0 22px;
  transition: 0.2s;
  [dir='rtl'] & {
    direction: ltr;
  }
}

.left-col-b {
  .last-result-container {
    max-height: 0;
    transition: max-height 230ms;
    overflow: hidden;

    &.open {
      max-height: 236px;
    }
  }

  .last-r-list-holder {
    margin-bottom: 20px;
  }

  .scroll-b-holder {
    border-top: 5px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
  }
}

.hide-last-result {
  height: 0;
}

.last-r-title-time {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  line-height: 16px;
  color: rgba(var(--text-color), 0.5);
  margin-bottom: 10px;
}

.last-r-list-holder {
  width: 100%;
}

.single-res-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.row-number-holder {
  width: 22px;
  font-size: 14px;
  line-height: 16px;
  color: rgba(var(--text-color), 0.5);
  max-width: 22px;
  min-width: 22px;
  flex: 1;

  > sup {
    top: -0.8em;
  }
}

.numbers-type-row {
  width: 100%;
  flex: 2;
  overflow: hidden;
  height: 42px;
  background-color: rgba(var(--skin-color-1), 1);
  border-radius: 22px;
  border: solid 2px rgba(var(--skin-color-3), 1);
  padding: 4px 0 4px 4px;
  margin-top: -2px;
}

.ball-line-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  &.animation {
    .ball {
      transform: translateX(250px);
      animation: circle-an 1s both linear;
    }

    .ball:nth-child(2) {
      animation-delay: 500ms;
    }

    .ball:nth-child(3) {
      animation-delay: 800ms;
    }

    .ball:nth-child(4) {
      animation-delay: 1100ms;
    }

    .ball:nth-child(5) {
      animation-delay: 1400ms;
    }
  }
}

@keyframes circle-an {
  0% {
    transform: translateX(250px) rotate(360deg);
  }
  100% {
    transform: translateX(0) rotate(-360deg);
  }
}

.ball {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(var(--hero-color), 1);
  background-image: url("../../images/ball.svg");
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  transition: background-color, opacity 230ms;
  opacity: 0.5;

  &.active {
    opacity: 1;
  }

  > img {
    display: block;
    max-width: 80%;
    max-height: 80%;
  }

  > span {
    color: rgba(var(--hero-color-set), 1);
  }

  &.green {
    background-color: #00d924;
    opacity: 1;
  }
}

//Last result END---------------------------

//Bet type start-----------------------------
.bet-type-holder {
  width: 100%;
}

.b-type-title {
  width: 100%;
  margin-bottom: 10px;
}

.title-text-v {
  width: 100%;
  color: rgba(var(--text-color), 0.85);
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;
}

.type-grid {
  width: 100%;
  height: 38px;
  border-radius: 6px;
  display: grid;
  grid-template-columns: auto 38px;
  grid-column-gap: 1px;
  margin-bottom: 2px;
  cursor: pointer;

  &.active,
  &:hover {
    .n-type-holder,
    .info-i-holder {
      background-color: rgba(var(--hero-color), 1);
      color: rgba(var(--hero-color-set), 1);
    }

    .type-btn {
      opacity: 1;
    }
  }

  &.active {
    pointer-events: none;
  }
}

.n-type-holder {
  background-color: rgba(var(--skin-color-3), 1);
  width: 100%;
  position: relative;
  color: rgba(var(--text-color), 0.65);
  transition: background-color 240ms, color 240ms;

  [dir='ltr'] & {
    border-radius: 6px 0 0 6px;
  }

  [dir='rtl'] & {
    border-radius: 0 6px 6px 0;
  }
}

.type-name-box {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 6px 0 10px;
}

.user-n {
  width: 100%;
  flex: 1;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  //display: -webkit-box;
  //-webkit-line-clamp: 2;
  //-webkit-box-orient: vertical;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 5px 0 0;
  text-transform: capitalize;
  position: relative;
}

.type-btn {
  display: inline-block;
  vertical-align: top;
  flex: 0 0 auto;
  width: 54px;
  height: 26px;
  border-radius: 6px;
  background-image: linear-gradient(
                  to bottom,
                  rgba(var(--brand-color-2-1), 1),
                  rgba(var(--brand-color-2), 1) 46%,
                  rgba(var(--brand-color-2-2), 1)
  );
  opacity: 0.75;
  transition: opacity 240ms;
  color: rgba(var(--brand-color-2-set), 1);
  font-weight: 700;
  font-size: 14px;
  border: none;
  box-sizing: border-box;
  font-family: inherit;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  > span {
    display: inline-block;
    vertical-align: top;
    position: relative;
    z-index: 22;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    background: rgba(#ffffff, 0.5);
    height: 180%;
    transform: rotate(-30deg);
  }

  &:before {
    width: 7px;
    top: -40%;
    left: 10px;
  }

  &:after {
    width: 2px;
    top: -40%;
    left: 21px;
  }
}

.info-i-holder {
  background-color: rgba(var(--skin-color-3), 1);
  color: rgba(var(--text-color), 0.65);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  //width: 38px;
  font-size: 28px;
  transition: background-color 240ms, color 240ms;

  [dir='ltr'] & {
    border-radius: 0 6px 6px 0;
  }

  [dir='rtl'] & {
    border-radius: 6px 0 0 6px;
  }
}

//Bet type END-------------------------------

// history START
.history-holder {
  width: 100%;
}

.history-holder:nth-child(3) {
  position: relative;
}

.history-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  min-height: 44px;

  &.search-visible {
    .search-cnt {
      transform: translate(0, 0);
      width: 100%;
      opacity: 1;
    }

    .history-h-column {
      opacity: 0;
    }

    & + {
      .history-date-text {
        opacity: 0;
      }
    }
  }
}

.numpad {
  display: grid;
  grid-gap: 2px;
  grid-template-columns: repeat(6, 1fr);
  border-radius: 6px;
  overflow: hidden;
  margin: 0 0 10px 0;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    height: 40px;
    background: rgba(var(--skin-color-3), 1);
    font-size: 12px;
    transition: background 230ms;
    cursor: pointer;

    &.full {
      grid-row: span 2;
      height: 100%;
    }

    &:hover {
      background: rgba(var(--skin-color-4), 1);
    }
  }
}

.history-h-column {
  overflow: hidden;
  opacity: 1;
  transition: opacity 200ms;

  &.disabled {
    pointer-events: none;
  }

  &.icon {
    flex: 0 0 auto;
  }

  &.text {
    flex: 1;
  }
}

.search-cnt {
  display: flex;
  overflow: hidden;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  background: rgba(var(--hero-color), 1);
  border-radius: 6px;
  opacity: 0.4;
  transform: translate(110%, 0);
  transition: 240ms linear;
  width: 0;
  color: rgba(var(--hero-color-set), 1);

  > input {
    flex: 1;
    outline: none;
    border: none;
    background: transparent;
    height: 100%;
    padding: 0 6px;
    box-sizing: border-box;
    font-size: 13px;
    color: rgba(var(--hero-color-set), 1);

    &::placeholder {
      color: rgba(var(--hero-color-set), 0.65);
    }
  }

  > i {
    flex: 0 0 auto;
    font-size: 26px;
    margin: 0 4px;
    cursor: pointer;
  }
}

@keyframes search {
  0% {
    opacity: 0.4;
    transform: translate(110%, 0);
    width: 0;
  }
  100% {
    transform: translate(0, 0);
    width: 100%;
    opacity: 1;
  }
}

.history-h-icon {
  display: block;
  font-size: 24px;
  color: rgba(var(--text-color), 0.45);
  cursor: pointer;
  transition: transform 240ms;

  &.filter {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #c40000;
      right: 2px;
      top: 4px;
      display: none;
    }

    &.active {
      &:after {
        display: block;
      }
    }
  }

  &.animated {
    transform: rotate(180deg);
  }

  &.active {
    color: rgba(var(--text-color), 1);
  }
}

.history-h-text {
  display: block;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
}

.history-date-text {
  //margin-top: 2px;
  margin-bottom: 8px;
  font-size: 10px;
  line-height: 14px;
  color: rgba(var(--text-color), 0.45);
  text-align: center;
  transition: opacity 200ms;
}

.history-content {
  width: 100%;

  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

.history-c-title {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  word-break: break-word;
  margin-bottom: 8px;
  font-size: 10px;
  line-height: 14px;
  color: rgba(var(--text-color), 0.45);
  padding: 0 6px;
}

.history-c-row {
  display: flex;
  align-items: center;
  word-break: break-word;
  text-transform: uppercase;
  position: relative;
  height: 38px;
  width: 100%;
  border-radius: 6px;
  background: rgba(var(--skin-color-3), 1);
  transition: background 230ms;
  margin-bottom: 2px;
  font-size: 10px;
  line-height: 14px;
  cursor: pointer;

  [dir='ltr'] & {
    padding: 0 0 0 10px;
    > button {
      border-left: 1px solid rgba(var(--skin-color-1), 1);
    }
  }

  [dir='rtl'] & {
    padding: 0 10px 0 0;
    > button {
      border-right: 1px solid rgba(var(--skin-color-1), 1);
    }
  }

  > span {
    flex: 1;

    &:first-child {
      flex: 1;

      & ~ * {
        flex: 0 0 auto;
      }
    }
  }

  > button {
    border: none;
    outline: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 31px;
    color: rgba(var(--text-color), 1);
    overflow: hidden;
    > i {
      font-size: 22px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &.active {
    background: rgba(var(--hero-color), 1);
    color: rgba(var(--hero-color-set), 1);
  }

  .circle {
    content: "";
    display: block;
    border-radius: 50%;
    margin: 0 10px;
    width: 6px;
    height: 6px;
    background: rgba(var(--skin-color-2), 1);
    border: solid 1px rgba(var(--skin-color-2), 1);
    transition: backgroun 230ms;
  }

  &.has-rebet {
    padding: 0 43px 0 10px;
  }

  &.status-3 {
    .circle {
      background: rgba(var(--status-color-3), 1);
    }
  }

  &.status-2 {
    .circle {
      background: rgba(var(--status-color-2), 1);
    }
  }

  &.status-1 {
    .circle {
      background: rgba(var(--status-color-1), 1);
    }
  }
}

.history-date-picker-container {
  width: 100%;
  overflow: hidden;
  max-height: 0;
  transition: max-height 230ms linear;

  &.open {
    max-height: 520px;
  }
}

.date-picker {
  width: 100%;
  min-height: 200px;
  border-radius: 6px;
  background: rgba(var(--skin-color-3), 1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  margin-bottom: 2px;
  margin-top: 10px;

  > div {
    width: 100%;
  }
}

.history-sort-row {
  width: 100%;
  margin-bottom: 10px;

  &.open {
    .history-sort-r-inner {
      max-height: 220px;
    }

    .h-sort-r {
      > i {
        transform: rotateZ(180deg);
      }

      > span {
        display: none;
      }
    }
  }
}

.history-sort-r-inner {
  max-height: 0;
  overflow: hidden;
  transition: max-height 230ms linear;
}

.h-sort-r {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 100%;
  background: rgba(var(--skin-color-3), 1);
  border-radius: 6px;
  margin-top: 8px;
  font-size: 10px;
  text-transform: capitalize;
  cursor: pointer;
  overflow: hidden;
  transition: max-height 230ms linear;

  > i {
    transition: transform 230ms;
  }
}

// history END

//left column END-------------------------------------------

// last result type-2 START
.last-result-type-2 {
  width: 100%;
  padding: 0 20px;
  margin-bottom: 20px;
}

.last-r-t-2-row {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 42px;
  margin-top: -2px;
}

.last-r-t-2-number {
  flex: 0 0 auto;
  margin-right: 8px;
}

.last-r-t-2-balls {
  display: flex;
  align-items: center;
  flex: 1;
  background: rgba(var(--skin-color-1), 1);
  border-radius: 21px;
  border: 2px solid rgba(var(--skin-color-3), 1);
  padding: 4px;
  justify-content: space-between;
}

.last-r-t-2-column {
  min-height: 30px;
  display: flex;
  align-items: center;
  background-color: rgba(var(--hero-color), 1);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
  border-radius: 22px;
  box-shadow: 0 0 0 2px rgba(var(--skin-color-3), 1) inset,
  3px 3px 0 rgba(var(--text-color), 0.12) inset, 2px -1px 0 rgba(var(--text-color), 0.12) inset;
  padding: 0 6px;
  flex: 1;
  margin-right: 14px;

  &.circle {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    justify-content: center;
    margin-right: 0;
    flex: 0 0 auto;
  }
}

.last-r-t-2-numbers {
  font-size: 14px;
  color: rgba(var(--text-color), 1);
  font-weight: 900;
  letter-spacing: 3px;
  word-break: break-all;
}

.last-r-t-2-animal {
  margin-left: 14px;
}

.last-r-t-2-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.last-r-t-2-img {
  width: 24px;
  display: block;
}

.last-game-drop {
  font-size: 10px;
  color: rgba(var(--text-color), 0.65);
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.last-game-d-inner {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  overflow: hidden;

  > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 90%;
  }

  i {
    font-size: 24px;

    &.up {
      transform: rotate(180deg);
    }
  }
}

// last result type-2 END

// result popup START
.result-popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--skin-color-1), 0.7);
  z-index: 99999;
  padding: 48px 10px;

}

.result-popup-content {
  display: grid;
  grid-template-rows: auto 11fr;
  grid-gap: 20px;
  max-width: 854px;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: rgba(var(--skin-color-2), 1);
  padding: 20px 20px 16px 20px;
  animation: result-p 240ms linear;
}

@keyframes result-p {
  0% {
    transform: translate(0, -30px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.result-popup-h-title {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  > span {
    flex: 1;
    text-align: center;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 20px;
  }

  > i {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid rgba(var(--text-color), 0.6);
    color: rgba(var(--text-color), 0.6);
    transition: 230ms;

    &:hover {
      color: rgba(var(--text-color), 1);
      border-color: rgba(var(--text-color), 1);
    }
  }
}

.result-popup-key-salt-row {
  display: grid;
  grid-template-columns: 4fr 4fr 190px;
  grid-gap: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.result-popup-input {
  outline: none;
  border: none;
  width: 100%;
  border-radius: 6px;
  background-color: rgba(var(--skin-color-1), 1);
  opacity: 0.65;
  text-transform: capitalize;
  font-size: 14px;
  height: 38px;
  box-sizing: border-box;
  padding: 0 12px;
  color: rgba(var(--text-color), 1);
}

.result-popup-button {
  outline: none;
  border: none;
  height: 38px;
  width: 100%;
  padding: 0 12px;
  border-radius: 6px;
  font-size: 18px;
  color: rgba(var(--text-color), 1);
  text-transform: uppercase;
  background-image: linear-gradient(to top, #00d924 99%, #86ff00 1%);
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.4;
    cursor: default;
  }
}

/////////////////////////
.result-popup-table {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: auto 11fr;
  border-radius: 6px;
  background: rgba(var(--skin-color-1), 0.65);
  padding: 10px;
}

.result-popup-t-head {
  margin-bottom: 10px;

  .result-popup-t-row {
    height: auto;
  }

  .result-popup-t-row-cell {
    background: transparent;
    border-color: transparent;
  }

  .type-3 {
    cursor: default !important;

    &:hover {
      background: transparent !important;
      color: rgba(var(--text-color), 0.65) !important;
    }
  }
}

.result-popup-t-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.result-popup-t-row {
  display: table;
  table-layout: fixed;
  height: 38px;
  width: 100%;
  margin-bottom: 2px;
  font-size: 10px;
  color: rgba(var(--text-color), 0.65);

  &:last-child {
    margin-bottom: 0;
  }
}

.result-popup-t-row-cell {
  display: table-cell;
  vertical-align: middle;
  background: rgba(var(--skin-color-3), 0.65);
  border-right: 1px solid rgba(var(--skin-color-1), 0.65);
  padding: 0 10px;
  transition: background, color 230ms;

  &.common-hover {
    padding: 0;
    cursor: pointer;

    &:hover {
      background: rgba(var(--hero-color), 1);
      color: rgba(var(--hero-color-set), 1);
    }
  }

  &.type-1 {
    width: 80px;
  }

  &.type-2 {
    width: 138px;
  }

  &.type-3 {
    width: 26.5%;
  }

  &:last-child {
    cursor: pointer;

    &:hover {
      background: rgba(var(--hero-color), 1);
      color: rgba(var(--hero-color-set), 1);
    }
  }

  [dir='ltr'] & {
    &:first-child {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-right: 0;
    }
  }

  [dir='rtl'] & {
    &:first-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-right: 0;
    }

    &:last-child {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }
  }

  > span {
    display: block;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &.active {
    background: rgba(var(--hero-color), 1);

    .animals-i-icon {
      transform: rotate(180deg);
      color: rgba(var(--text-color), 1);
    }
  }
}

.result-popup-t-row-c-i-table {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.result-popup-t-row-c-i-t-cell {
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 1px;
  padding: 0 10px;

  > span {
    display: block;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &:first-child {
    border-right: 1px solid rgba(var(--skin-color-1), 0.65);
  }
}

.result-popup-t-row-c-inner {
  width: 100%;
  display: flex;

  > span {
    flex: 1;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > i {
    flex: 0 0 auto;
    cursor: pointer;
  }
}

.animals-i-icon {
  font-size: 24px;
  cursor: pointer;
}

.animals-i-tooltip {
  position: absolute;
  display: none;
  top: 31px;
  height: 44px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(var(--skin-color-2), 1);
  animation: animal-tooltip 230ms linear;
  padding: 8px 5px;
  z-index: 99;

  [dir='ltr'] & {
    left: 0;

    &:before {
      left: 20%;
    }
  }

  [dir='rtl'] & {
    right: 0;

    &:before {
      right: 20%;
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    background-color: rgba(var(--skin-color-2), 1);
  }
}

// result popup END
.mini-animals-row {
  display: flex;
  margin: 0 4px;
}

.mini-animals-img {
  max-width: 16px;
  max-height: 16px;
  display: block;
  margin: 0 1px;
}

//left column start-----------------------------------------

.tab-row-b {
  width: 100%;
  grid-area: icon-tabs;
}

// last result type-2 START
.last-result-type-2 {
  width: 100%;
  padding: 0 20px;
  margin-bottom: 20px;
}

.last-r-t-2-row {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 42px;
  margin-top: -2px;
}

.last-r-t-2-number {
  flex: 0 0 auto;
  margin-right: 8px;
  font-size: 14px;
}

.last-r-t-2-balls {
  display: flex;
  align-items: center;
  flex: 1;
  background: rgba(var(--skin-color-1), 1);
  border-radius: 21px;
  border: 2px solid rgba(var(--skin-color-3), 1);
  padding: 4px;
  justify-content: space-between;
}

.last-r-t-2-numbers {
  font-size: 14px;
  color: rgba(var(--text-color), 1);
  font-weight: 900;
  letter-spacing: 3px;
  word-break: break-all;
}

.last-r-t-2-animal {
  margin-left: 14px;
}

.last-r-t-2-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.last-r-t-2-img {
  width: 24px;
  display: block;
}

// last result type-2 END
// date picker sort START
.date-picker-sort-columns {
  display: flex;
  width: 100%;
  margin: 10px 0;
  align-items: center;

  &.moz {
    .date-picker-sort-icon {
      width: 14px;
    }
  }
}

.date-picker-sort-c {
  flex: 1;
  font-size: 14px;

  &.center {
    flex: 0 0 auto;
    margin: 0 5px;
  }
}

.date-picker-sort-content {
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  background-color: rgba(var(--skin-color-3), 1);
  align-items: center;
  height: 38px;
}

.date-picker-sort-icon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  background-color: rgba(var(--hero-color), 1);
  color: rgba(var(--text-color), 1);
  opacity: 0.4;
  height: 100%;
  font-size: 28px;
  transition: opacity 230ms;
  cursor: pointer;

  &.active,
  &:hover {
    opacity: 1;
  }
  &.passive {
    pointer-events: none;
  }
}

.date-picker-sort-numbers {
  &[type="time"]::-webkit-calendar-picker-indicator {
    //background: none;
    display: none;
  }

  outline: none;
  border: none;
  background: transparent;
  padding: 0 2px;
  flex: 1;
  word-break: break-all;
  font-size: 10px;
  color: rgba(var(--text-color), 1);
  box-sizing: border-box;
  width: 30px;
  min-width: 30px;
  text-align: center;

  &::placeholder {
    color: rgba(var(--text-color), 1);
    text-align: center;
  }
}

/////////
.result-filters-changes-row {
  display: flex;
  width: 100%;
  height: 38px;
  border-radius: 6px;
  background: rgba(var(--skin-color-3), 1);
  margin-bottom: 2px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  cursor: pointer;
  color: rgba(var(--text-color), 0.45);
  transition: 230ms;

  &:hover,
  &.active {
    background: rgba(var(--hero-color), 1);
    color: rgba(var(--hero-color-set), 1);
  }
}

.result-filters-c-column {
  display: flex;
  align-items: center;
  word-break: break-word;
  text-transform: uppercase;
  font-size: 10px;
  text-align: center;

  &:first-child {
    max-width: 64px;
  }
}

// date picker sort END
// result popup START
/////////////////////////
.animals-item {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  &:hover {
    .animals-i-tooltip {
      display: flex;
    }
  }
}

.animals-i-t-column {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 5px;

  [dir='ltr'] & {
    border-right: 1px solid rgba(var(--skin-color-1), 0.65);

    &:last-child {
      border-right: 0;
    }
  }

  [dir='rtl'] & {
    border-left: 1px solid rgba(var(--skin-color-1), 0.65);

    &:first-child {
      border-left: 0;
    }
  }

  > span {
    font-size: 10px;
  }

  > p {
    font-size: 10px;
    font-weight: 900;
    letter-spacing: 2px;
    color: rgba(var(--text-color), 1);
    padding-left: 4px;
  }
}

@keyframes animal-tooltip {
  0% {
    transform: translate(0, -20px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.sticky-container {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: rgba(var(--skin-color-2), 1);
  z-index: 99;
  padding-bottom: 2px;
}

// result popup END
//left column END-------------------------------------------
