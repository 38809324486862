html {
  width: 100%;
  height: 100%;
  background-color: rgba(var(--skin-color-1), 1);
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
}

body {

  width: 100%;
  height: 100%;
  font-family: var(--font-f);
  font-weight: 400;
  color: rgba(var(--text-color), 1);
  -webkit-overflow-scrolling: touch;
}

a {
  color: rgba(var(--text-color), 1);
}

#root {
  width: 100%;
  height: 100%;
  position: relative;
}

.main-wrapper {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto 11fr auto;
  grid-template-areas: "left-column center-column right-column";
  grid-column-gap: 16px;
  user-select: none;
  width: 1196px;
  height: 700px;
  padding: 28px 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -592px;
  margin-top: -350px;
}


.left-col-b {
  grid-area: left-column;
  width: 270px;
}

.center-col-b {
  grid-area: center-column;
  background: rgba(var(--skin-color-2), 1) url("../../images/BG.png") no-repeat 50% 50% / auto 100%;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  //&.game-page{
  //  background: rgba(var(--skin-color-2), 1) url("../../images/background.png") no-repeat 50% 50% / auto 100%;
  //}
}

.right-col-b {
  width: 320px;
  grid-area: right-column;
}

.grid-wrap-b {
  width: 100%;
  height: 100%;
  position: relative;
}

.live-bets-cnt {
  width: 100%;
}

.main-wrapper {
  .table-title-row {
    & ~ .st-content-info:empty:nth-child(14) ~ div {
      display: none;
    }
  }
}

.scroll-b-holder {
  width: 100%;
  height: 100%;
  position: relative;
  border: 10px solid transparent;

  [dir='ltr'] & {
    border-right-width: 6px;
  }

  [dir='rtl'] & {
    border-left-width: 6px;
  }
}

.scroll-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.no-matching {
  padding: 15px;
  text-align: center;
}

@media (min-width: 813px) {
  .scroll-container {
    scrollbar-color: rgba(var(--skin-color-5), 0.5) rgba(var(--skin-color-1), 0.1);
    scrollbar-width: thin;

    [dir='ltr'] & {
      padding-right: 4px;
    }

    [dir='rtl'] & {
      padding-left: 4px;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background: rgba(var(--skin-color-white), 0.1);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(var(--skin-color-5), 0.5)
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
    }
  }
}

// rebet START
.rebet-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--skin-color-1), 0.8);
  color: rgba(var(--text-color), 1);
  padding: 10px;
  z-index: 99999;
}

.rebet-popup-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 6px;
  min-width: 242px;
  min-height: 160px;
  background: rgba(var(--skin-color-2), 1);
  margin-bottom: 12px;
  animation: rebet-popup 180ms linear;

  > i {
    font-size: 48px;
    margin-bottom: 15px;
  }

  > p {
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 19px;
    line-height: 19px;
  }
}

.rebet-popup-c-buttons {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;

  > button {
    border: none;
    outline: none;
    height: 38px;
    border-radius: 6px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;

    &:first-child {
      background: rgba(var(--skin-color-3), 1);
      color: rgba(var(--text-color), 1);
    }

    &:last-child {
      background: rgba(var(--hero-color), 1);
      color: rgba(var(--hero-color-set), 1);
    }

    &:only-child {
      grid-column: span 2;
    }
  }
}

.rebet-popup-show-again {
  position: relative;
  display: flex;
  align-items: center;

  > input {
    display: none;

    &:checked + label:before {
      opacity: 1;
    }
  }

  > label {
    font-size: 12px;
    line-height: 16px;
    color: rgba(var(--text-color), 1);
    padding-left: 24px;
    position: relative;
    cursor: pointer;

    &:after,
    &:before {
      display: block;
      position: absolute;
    }

    &:after {
      content: "";
      width: 16px;
      height: 16px;
      border-radius: 2px;
      border: 1px solid rgba(var(--text-color), 1);
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto 0;
    }

    &:before {
      content: "\e962";
      font: 24px icomoon;
      left: -3px;
      top: -4px;
      opacity: 0;
      transition: opacity 180ms;
    }
  }
}

.rebet-icon {
  &:before {
    transform: rotate(-45deg) translate(0, -2px);
    display: block;
  }
}

.rebet-icon:hover {
  cursor: pointer;
}

@keyframes rebet-popup {
  0% {
    opacity: 0.4;
    transform: translate(0, -30px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

//rebet END
//Notifications start-----------------------------------------
.notification-holder-b {
  position: fixed;
  top: 10px;
  left: 50%;
  display: inline-flex;
  transform: translateX(-50%);
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 999999;
}

.notification-container {
  padding: 10px;
  background: rgba(var(--skin-color-2), 0.9);
  z-index: 9999;
  border-radius: 6px;
  display: flex;
  align-items: center;
  animation: 360ms notification-open ease-in-out forwards;
  margin-bottom: 5px;

  > p {
    font-size: 10px;
    line-height: 18px;
    white-space: nowrap;
    max-width: 100%;
  }

  > span {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: rgba(var(--text-color), 1);

    [dir='ltr'] & {
      margin-left: 10px;
    }

    [dir='rtl'] & {
      margin-right: 10px;
    }
  }

  &.accept {
    color: rgba(var(--status-color-1), 1);
    box-shadow: 0 0 0 1px rgba(var(--status-color-1), 0.8) inset;

    > span {
      background: rgba(var(--status-color-1), 1);
    }
  }

  &.warning {
    box-shadow: 0 0 0 1px rgba(var(--status-color-3), 0.8) inset;
    color: rgba(var(--status-color-3), 0.8);

    > span {
      background: rgba(var(--status-color-3), 1);
    }
  }

  &.error {
    box-shadow: 0 0 0 1px rgba(var(--status-color-2), 0.8) inset;
    color: rgba(var(--status-color-2), 1);

    > span {
      background: rgba(var(--status-color-2), 1);
    }
  }

  &:first-child {
    animation-delay: 100ms;
  }

  &:nth-child(2) {
    animation-delay: 180ms;
  }

  &:nth-child(3) {
    animation-delay: 260ms;
  }

  &:nth-child(4) {
    animation-delay: 340ms;
  }

  &:nth-child(5) {
    animation-delay: 420ms;
  }

  &:nth-child(6) {
    animation-delay: 500ms;
  }

  &:nth-child(7) {
    animation-delay: 580ms;
  }

  &:nth-child(8) {
    animation-delay: 660ms;
  }

  &:nth-child(9) {
    animation-delay: 740ms;
  }

  &:nth-child(10) {
    animation-delay: 820ms;
  }

  opacity: 0;
}

@keyframes notification-open {
  0% {
    clip-path: inset(50% round 50%);
    opacity: 1;
  }
  99% {
    clip-path: inset(0% round 0%);
    opacity: 1;
  }
  100% {
    opacity: 1;
    clip-path: inherit;
  }
}

.loading-cnt {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;

  &.inline {
    position: relative;
    right: 0;
    width: 100%;
    height: auto;
  }
}


.loading {
  display: flex;
  align-items: center;

  > span {
    margin: 0 2px;
    position: relative;
    font-size: 3.5em;
    opacity: 0;
    background: #ffffff;
    animation: loading 1.8s ease-in-out infinite;
    border-radius: 50%;

    &:nth-child(1) {
      animation-delay: 0.2s;
      width: 4px;
      height: 4px;
    }

    &:nth-child(2) {
      animation-delay: 0.4s;
      width: 6px;
      height: 6px;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
      width: 8px;
      height: 8px;
    }

    &:nth-child(4) {
      animation-delay: 0.8s;
      width: 10px;
      height: 10px;
    }
  }
}

.has-sticky-carcas {
  position: absolute;
  left: 0;
  top: 0;
  display: grid;
  grid-template-rows: auto 11fr;
  grid-template-areas: "head" "content";
  width: 100%;
  height: 100%;
}

.has-sticky-head {
  width: 100%;
  grid-area: head;
  overflow: hidden;
}

.has-sticky-content {
  width: 100%;
  grid-area: content;
}

.has-sticky-c-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../images/bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 99999;
  background-color: rgba(var(--skin-color-1), 1);
  flex-direction: column;

  &.mobile {
    .image-block-inner {
      width: 36vmin;
      height: 24vmin;
    }

    .logo-talisman {
      > img {
        width: auto;
        max-width: 50%;
      }
    }

    .loading-item {
      width: 88%;
      max-width: 500px;
      margin-top: 12vmin;
      border-radius: 2vmin;
      height: 2vmin;

      > span {
        border-radius: 2vmin;
      }
    }

    .betconstruct-word {
      bottom: 3vmin;
      font-size: 4.4vmin;
    }
  }
}

.loading-w-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image-block-inner {
  width: 203px;
  height: 129px;
  position: relative;

  > img {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    animation: pulse 2s infinite ease-in-out;
  }
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  to {
    transform: scale(1);
  }
}

.logo-talisman {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    display: block;
    width: 22%;
    max-width: 320px;
  }
}

.loading-item {
  width: 30%;
  max-width: 500px;
  height: 6px;
  border-radius: 6px;
  margin-top: 64px;
  overflow: hidden;
  position: relative;
  background-color: rgba(var(--skin-color-2), 1);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

  > span {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 6px;
    background-image: linear-gradient(to top, rgba(var(--brand-color-2-1), 1), rgba(var(--brand-color-2), 1) 54%, rgba(var(--brand-color-2-2), 1));
    transition: transform 230ms;
  }
  [dir='rtl'] & {
   transform: rotate(180deg);
  }
}

.betconstruct-word {
  width: 100%;
  position: absolute;
  bottom: 28px;
  font-size: 14px;
  text-align: center;

  > p {
    text-transform: uppercase;
    color: rgba(var(--text-color), 1);

    > span {
      text-transform: uppercase;
      color: rgba(var(--text-color), 0.7);
    }
  }
}

//Notifications END-------------------------------------------
.real-play-btn {
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  background-image: linear-gradient(to bottom, #78e300, #00b91f);
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  color: #ffffff;
  width: 116px;
  height: 28px;
}

// lobby web START
.main-lobby-wrapper {
  user-select: none;
  width: 1196px;
  height: 700px;
  padding: 28px 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -592px;
  margin-top: -350px;

  &:before {
    content: "";
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: url("../../images/bg-lobby-web.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  &:after {
    content: "";
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    //background: linear-gradient(to bottom, rgba(var(--skin-color-2), 0) 0%, rgba(var(--skin-color-2), 1) 100%);
    background-color: rgba(var(--skin-color-black), 0.5);
    backdrop-filter: blur(7px);
  }

  &.mobile {
    zoom: 1 !important;
    width: 100%;
    position: relative;
    height: 100%;
    margin: 0;
    left: 0;
    top: 0;
  }
}

@-moz-document url-prefix() {
  .main-lobby-wrapper {
      &:before,
      &:after,
      .loading-wrapper {
        width: 150%;
        height: 150%;
        left: -25%;
        top: -25%;
      }
  }
}

.lobby-web {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  //&:before {
  //  content: "";
  //  display: block;
  //  width: 240px;
  //  height: 240px;
  //  position: absolute;
  //  bottom: -60px;
  //  right: -45px;
  //  background-image: url("../../images/24.png");
  //  background-repeat: no-repeat;
  //  background-size: 100% auto;
  //  transform: rotate(95deg);
  //}
}

.lobby-web-content {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: grid;
  grid-template-areas: "left right";
  grid-template-rows: 12fr;
  grid-template-columns: 11fr auto;
  grid-column-gap: 16px;
  padding: 28px;
  overflow: hidden;
  z-index: 99;
  transform: translate3d(0, 0, 0);

  //&:before {
  //  content: "";
  //  display: block;
  //  width: 120px;
  //  height: 120px;
  //  position: absolute;
  //  top: -60px;
  //  left: -45px;
  //  background-image: url("../../images/24.png");
  //  background-repeat: no-repeat;
  //  background-size: 100% auto;
  //  transform: rotate(95deg);
  //}

  //&:after {
  //  content: "";
  //  display: block;
  //  width: 150px;
  //  height: 150px;
  //  position: absolute;
  //  top: -70px;
  //  right: 150px;
  //  background-image: url("../../images/06.png");
  //  background-repeat: no-repeat;
  //  background-size: 100% auto;
  //}
}

.lobby-w-left-column {
  grid-area: left;
  overflow: hidden;
  position: relative;
}

.lobby-w-right-column {
  grid-area: right;
  overflow: hidden;
}

.l-r-col-ins {
  position: relative;
  width: 304px;
  height: 100%;
  z-index: 9;
}

.l-left-col-ins {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: 4.6fr 6fr;
  grid-row-gap: 10px;
  grid-template-columns: 12fr;
}

.l-left-col-ins-top {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url("../../images/bg-lobby-web.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
  position: relative;
}

.l-left-col-ins-bottom {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 4px;
  scrollbar-color: rgba(var(--skin-color-5), 0.5) rgba(var(--skin-color-1), 0.1);
  scrollbar-width: thin;


  &::-webkit-scrollbar {
    height: 4px;
    background: rgba(var(--skin-color-white), 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(var(--skin-color-5), 0.5)
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
}

.l-top-ins-s {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 4px 0;
}

.l-top-ins-s-c {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.65);
  margin-left: 20px;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &.active {
    background-image: linear-gradient(to top, rgba(var(--brand-color-1-1), 1), rgba(var(--brand-color-1-2), 1));
    transform: scale(1.6);
    cursor: default;
  }
}

.l-top-ins-img-wr {
  width: 65%;
}

.l-top-ins-img-inside {
  width: 100%;

  img {
    display: block;
    width: 100%;
  }
}

.left-c-b-ins {
  width: 100%;
  white-space: nowrap;

  &.transform_X-left {
    transform: translateX(-100%);
  }

  &.transform_X-right {
    transform: translateX(100%);
  }
}

.left-c-b-ins-item {
  display: inline-grid;
  vertical-align: top;
  grid-template-rows: auto 11fr;
  //min-height: 254px;
  height: 314px;
  width: 240px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: rgba(var(--skin-color-1), 0.75);
  padding: 16px;
  border-radius: 6px;

  [dir='ltr'] & {
    margin-right: 16px;

    &:last-child {
      margin: 0;
    }
  }

  [dir='rtl'] & {
    margin-left: 16px;

    &:last-child {
      margin: 0;
    }
  }
}

.left-c-b-i-inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 10px;
}

.left-c-b-ins-i-timer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 26px;
  color: rgba(var(--text-color), 1);
  font-size: 18px;
  text-align: center;
  padding: 0 4px;
  margin-bottom: 23px;
  margin-top: 2px;
  font-weight: 800;

  [dir='rtl'] & {
    margin-bottom: 6px;
  }

  &.passive {
    color: rgba(var(--text-color), 0.5);
  }

  > span {
    display: block;
    transform: translate(-50px, 0);
    opacity: 0;
    animation: time-l 240ms ease-in-out forwards;
  }
}

@keyframes time-l {
  0% {
    transform: translate(-50px, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.left-c-b-ins-i-img {
  width: 100%;
  display: flex;
  justify-content: center;
}

.left-c-b-ins-i-img-ins {
  width: 100%;
  max-width: 120px;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.c-b-ins-i-t-ins {
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  min-width: 10px;
}

.c-b-ins-i-t-ins-left {
  flex: 0 0 auto;

  i {
    color: rgba(var(--text-color), 0.65);
    font-size: 18px;
  }
}

.c-b-ins-i-t-ins-right {
  flex: 1;
  overflow: hidden;
}

.time-p {
  //color: rgba(var(--text-color), 0.85);
  color: rgba(var(--text-color), 0.65);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  [dir='ltr'] & {
    padding-left: 6px;
  }

  [dir='rtl'] & {
    padding-right: 6px;
  }
}

.left-c-b-ins-i-btn {
  width: 100%;
  margin-top: 2px;
  text-align: center;
}

.btn-pl-n {
  width: 100%;
  max-width: 140px;
  height: 36px;
  padding: 0 4px;
  outline: none;
  color: rgba(var(--text-color), 0.85);
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.play-now {
    background-image: linear-gradient(to bottom, rgba(var(--brand-color-1-1), 1), rgba(var(--brand-color-1-2), 1));
    border: 1px solid #006511;
    margin-bottom: 10px;
  }

  &.demo {
    border: none;
    //background-color: rgba(var(--skin-color-3), 1);
    background-color: transparent;
    box-shadow: 0 0 0 1px rgba(var(--skin-color-white), 0.8);
  }
}

.b-ins-max-m {
  font-size: 20px;
  line-height: 24px;
  color: rgba(var(--text-color), 1);
  text-align: center;
  //margin-top: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.b-wr-money {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.b-wr-money-p {
  font-size: 28px;
  text-align: center;
  color: $status-color-3;
  font-weight: 600;
  margin-top: 4px;
  margin-bottom: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.b-mon-min-max-wr {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.b-mon-min-max {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
}

.b-mon-min-max-left {
  display: flex;
  overflow: hidden;
  padding-right: 4px;
}

.b-mon-min-max-right {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.b-min-l-con {
  flex: 1;
  overflow: hidden;
}

.b-min-r-con {
  flex: 0 0 auto;
  padding-left: 3px;
}

.b-min-l-con-p {
  font-size: 12px;
  color: rgba(var(--text-color), 0.65);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.b-min-r-con-p {
  font-size: 12px;
  color: rgba(var(--text-color), 0.65);
}

.left-c-b-ins-item-b {
  width: 100%;
}

.l-r-col-ins-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: "top" "middle" "bottom";
  grid-template-rows: auto auto 10fr;
  grid-template-columns: 12fr;
  padding: 10px;
  background: rgba(var(--skin-color-1), 0.65);
  border-radius: 6px;
}

.left-col-top {
  grid-area: top;
  overflow: hidden;
}

.left-col-tabs {
  width: 100%;
  display: flex;
  height: 34px;
  border-radius: 6px;
  overflow: hidden;

  > button {
    flex: 1;
    outline: none;
    border: none;
    font-size: 12px;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    background-color: rgba(var(--skin-color-2), 1);
    color: rgba(var(--text-color), 0.7);
    transition: 240ms all;

    &.active,
    &:hover {
      color: rgba(var(--text-color), 1);
      background-color: rgba(var(--hero-color), 1);
    }
  }
}

.left-col-middle {
  grid-area: middle;
  overflow: hidden;
}

.left-col-bottom {
  grid-area: bottom;
  overflow: hidden;
  position: relative;
}

.left-col-top-ins {
  width: 100%;
  display: flex;
  align-items: center;
}

.left-col-top-ins-left {
  flex: 1;
  overflow: hidden;
}

.left-col-top-ins-right {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  overflow: hidden;
}

.title-p-top {
  color: rgba(var(--text-color), 1);
  padding-right: 6px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.top-r-sq {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: rgba(var(--skin-color-2), 0.65);
  cursor: pointer;
  transition: background-color 240ms;

  &:last-child {
    margin-left: 1px;
  }

  i {
    color: rgba(var(--text-color), 0.65);
    font-size: 26px;
    transition: color 240ms;
  }

  &:hover {
    background: rgba(var(--skin-color-2), 1);

    i {
      color: rgba(var(--text-color), 1);
    }
  }

  &.active {
    background: rgba(var(--skin-color-2), 1);
    cursor: default;

    i {
      color: rgba(var(--text-color), 1);
    }
  }
}

.l-c-m-ins {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.l-c-m-ins-col {
  flex: 1;
  overflow: hidden;
  padding-right: 6px;

  &:last-child {
    padding-right: 0;
  }
}

.l-c-m-ins-col-p {
  font-size: 12px;
  color: rgba(var(--text-color), 0.45);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.center {
    text-align: center;
  }

  &.right {
    [dir='ltr'] & {
      text-align: right;
    }

    [dir='rtl'] & {
      text-align: left;
    }

  }
}

.left-col-g-row {
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  border-radius: 6px;
  background-color: rgba(var(--skin-color-3), 1);
  margin-bottom: 2px;

  &:empty {
    background-color: rgba(var(--skin-color-1), 1);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.left-col-g-row-in {
  flex: 1;
  overflow: hidden;
  padding: 0 12px;
}

.left-col-g-row-in-p {
  color: rgba(var(--text-color), 1);
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 6px;

  &:last-child {
    padding-right: 0;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}

.for-lobby {
  position: absolute;
  font-size: 30px;
  z-index: 100;
  bottom: -50px;

  [dir='ltr'] & {
    right: 10px;
  }

  [dir='rtl'] & {
    left: 10px;
  }

  &.mobile {
    position: fixed;
    top: 5rem;
    bottom: auto;
    font-size: 3rem;

    [dir='ltr'] & {
      right: 1rem;
    }

    [dir='rtl'] & {
      left: 1rem;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

// lobby web END
