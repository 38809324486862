.choose-number-t-holder {
  width: 100%;
  height: 100%;
  display: grid;
  position: absolute;
  grid-template-rows: auto 10fr auto;
  grid-template-areas: "chosse-n-text" "ch-numbers" "bet-amaunt";
  left: 0;
  top: 0;
  transition: transform 240ms;

  [dir='rtl'] & {
    direction: ltr;
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    border-radius: 50%;
    width: 810px;
    height: 810px;
    top: 0;
    left: 50%;
    background-color: rgba(#000000, 0.1);
    transform: translate(-50%, -58%);
  }
}

.ch-title-text-r {
  width: 100%;
  grid-area: chosse-n-text;
  text-align: center;
  padding-top: 20px;
}

.ch-title-cnt {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 10px 10px 10px;
}

.ch-title-info {
  color: rgba(var(--text-color), 0.85);
  //font-size: 2.8vmin;
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;

  > span {
    position: absolute;
    display: block;
    right: -12px;
    top: -8px;
    transition: transform 230ms;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.ch-p-description {
  //max-width: 70%;
  max-width: 260px;
  margin: 0 auto;
  //font-size: 1.8vmin;
  //line-height: 2vmin;
  font-size: 10px;
  line-height: 14px;
  color: rgba(var(--text-color), 0.85);
  word-break: break-word;
}

.ch-numbers-b {
  width: 100%;
  grid-area: ch-numbers;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  transition: transform 240ms;
  position: relative;
  z-index: 9;
}

.ch-numbers-img {
  display: block;
  //max-width: 24vmin;
  //min-width: 50px;
  width: 160px;
  height: auto;
}

.ch-numbers-b-img {
  display: block;
  max-width: 80%;
  max-height: 90%;
  margin: 0 auto;
}

.bet-amount-row {
  width: 100%;
  grid-area: bet-amaunt;
  text-align: center;
  padding-bottom: 4%;
}

.numbers-ch-title {
  color: rgba(var(--text-color), 0.85);
  //font-size: 1.8vmin;
  //line-height: 2vmin;
  //padding-bottom: 1.8vmin;
  padding-bottom: 8px;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
}

.ch-numbers-cnt {
  width: 100%;

  .single-ch-number {
    //width: 6.8vmin;
    //height: 6.8vmin;
    //font-size: 2.4vmin;
    width: 46px;
    height: 46px;
    font-size: 14px;
  }
}

.ch-numbers-cnt-fixed {
  pointer-events: none;

}

.ch-numbers-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
  position: relative;

  .ch-numbers-box {
    padding-bottom: 0 !important;
  }
}

.ch-numbers-box-active {
  pointer-events: none;
  opacity: 0.3;
}

.single-ch-number {
  width: 60px;
  height: 60px;
  //width: 9.4vmin;
  //height: 9.4vmin;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 240ms all;
  background-color: rgba(var(--hero-color), 0.5);
  cursor: pointer;
  font-size: 18px;
  //font-size: 3vmin;
  font-weight: 700;
  color: rgba(var(--hero-color-set), 1);
  margin: 0 5px;
  border: 1px solid rgba(var(--hero-color), 1);
  position: relative;

  &.active {
    background-color: rgba(var(--hero-color), 1);

    .single-ch-n-shadow {
      opacity: 1;
    }
  }

  &.selected {
    border-color: transparent;
    background-color: rgba(var(--brand-color-1), 1);

    .single-ch-n-shadow {
      opacity: 1;
    }
  }

  &.has-border {
    &:before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 30%;
      width: 30%;
      height: 2%;
      background: rgba(var(--text-color), 0.45);
    }
  }

  &.has-img {
    box-shadow: none;
    background-color: rgba(var(--hero-color), 0.3);
  }

  > span {
    z-index: 999;
    position: relative;
  }
}

.single-ch-n-shadow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  transition: opacity 230ms;
  background-image: url("../../images/ball.svg");
  background-size: cover;
  opacity: 0;
}

.four-chance-input {
  width: 100%;
  outline: none;
  background: transparent;
  border: none;
  text-align: center;
  color: rgba(var(--text-color), 1);
  font-size: 18px;
  font-weight: 900;
  padding: 0 2px;
  box-sizing: border-box;
}

.win-text {
  font-size: 22px;
  line-height: 24px;
  font-weight: normal;
  color: rgba(var(--text-color), 0.85);
  position: relative;

  > span {
    font-weight: 900;
    text-transform: uppercase;
  }
}

.money-v {
  position: relative;
  color: rgba(var(--brand-color-2-3), 1);
  font-size: 60px;
  font-weight: 600;
}

// numpad animals START
.animal-numpad {
  display: grid;
  position: absolute;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1px;
  left: 50%;
  transform: translate(-50%, -30px);
  top: 68px;
  background-color: rgba(var(--skin-color-1), 1);
  box-shadow: 0 0 5px 0 rgba(var(--skin-color-black), 0.5);
  border-radius: 6px;
  overflow: hidden;
  z-index: 99;
  animation: animal-num 240ms linear forwards;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: rgba(var(--text-color), 1);
    background-color: #00547a;
    width: 40px;
    height: 32px;

    &.has-icon {
      background-color: rgba(var(--hero-color), 1);
      color: rgba(var(--hero-color-set), 1);
      font-size: 18px;
    }
  }
}

@keyframes animal-num {
  0% {
    transform: translate(-50%, -30px);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

// numpad animals END
// game results START
.game-results-container {
  z-index: 99999;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 40px;

  &:before {
    content: "";
    position: absolute;
    display: block;
    border-radius: 50%;
    width: 810px;
    height: 810px;
    top: 0;
    left: 50%;
    background-color: rgba(#000000, 0.1);
    transform: translate(-50%, -66%);
  }
}

.new-game-results-container {
  display: grid;
  grid-template-columns: 12fr;
  grid-template-rows: 6fr 6fr;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: .3s;
}


.game-results-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
  position: relative;
  //z-index: 9;
  //&:only-child {
  //  grid-row: span 2;
  //}
  &:last-child {
    margin-bottom: 0;
  }

  &.head {
    display: grid;
    grid-template-rows: auto 11fr;
    width: 100%;
    justify-content: initial;
    align-items: initial;
    margin-bottom: 0;
  }
}


.game-results-r-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.game-results-content {
  //width: 40vmin
  width: 270px;
}

.game-results-img {
  display: block;
  //width: 20vmin;
  //margin-bottom: 2vmin;
  width: 142px;
  margin-bottom: 20px;
}

.game-results-text {
  //font-size: 3.6vmin;
  //line-height: 3.8vmin;
  font-size: 28px;
  line-height: 30px;
  color: rgba(var(--text-color), 1);
  text-transform: capitalize;
  font-weight: 600;

  &.skeleton {
    margin: 0 auto 4px auto;
  }
}

.game-results-time {
  //font-size: 2vmin;
  font-size: 18px;
  color: rgba(var(--text-color), 1);
}

// game results END

//lost connection popup START
.lost-connection-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  left: 0;
  top: 0;
  background-color: rgba(var(--skin-color-1), 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.lost-connection-p-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 426px;
  max-height: 418px;
  border-radius: 6px;
  background-color: rgba(var(--skin-color-2), 1);
  padding: 10px;
  animation: win-popup 300ms linear;

  > h3 {
    color: rgba(var(--text-color), 0.85);
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 4px;
  }

  > span {
    font-size: 10px;
    color: rgba(var(--text-color), 0.65);
  }

  > button {
    outline: none;
    border: none;
    margin-top: 37px;
    min-width: 183px;
    height: 38px;
    border-radius: 6px;
    background-color: rgba(var(--hero-color), 1);
    cursor: pointer;
    text-transform: uppercase;
    color: rgba(var(--hero-color-set), 0.85);
    font-size: 24px;
  }
}

.lost-connection-img {
  display: block;
  width: 80%;
}

//lost connection popup END
// total win popup START
.total-win-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(var(--skin-color-1), 0.7);
  z-index: 9999;
}

.total-win-popup-cnt {
  //width: 31vmin;
  //height: 31vmin;
  width: 306px;
  height: 306px;
  animation: win-popup 440ms linear;
  position: relative;
}

.total-win-popup-cnt-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-image: linear-gradient(to bottom, #feff72, #fed700 46%, #fe8100);
  border-radius: 50%;
  z-index: 99;
}

.circle-big,
.circle-small {
  position: absolute;
  display: block;
  border-radius: 50%;
}

.circle-big {
  width: 60%;
  height: 60%;
  left: -8%;
  bottom: 0;
  background: rgba(var(--skin-color-3), 1);
}

.circle-small {
  width: 36%;
  height: 36%;
  top: 0;
  right: 0;
  background: rgba(var(--hero-color), 1);
}

@keyframes win-popup {
  0% {
    opacity: 0.4;
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.total-win-title {
  //font-size: 2.6vmin;
  font-size: 24px;
  color: rgba(var(--skin-color-1), 1);
  //line-height: 3vmin;
  line-height: 28px;
  text-transform: capitalize;
}

.total-win-money {
  //font-size: 6vmin;
  font-size: 40px;
  //line-height: 6.8vmin;
  line-height: 58px;
  font-weight: 900;
  color: rgba(var(--skin-color-1), 1);
}

.total-win-button {
  outline: none;
  border: none;
  //height: 5vmin;
  height: 48px;
  border-radius: 0.8vmin;
  background: rgba(var(--skin-color-2), 1);
  //min-width: 14vmin;
  min-width: 134px;
  //margin-top: 1vmin;
  margin-top: 8px;
  //font-size: 2vmin;
  font-size: 18px;
  color: rgba(var(--text-color), 0.85);
  text-transform: uppercase;
}

// total win popup END
// help game START
.game-help-container {
  display: grid;
  grid-template-columns: 12fr;
  grid-template-rows: auto 10fr;
  grid-gap: 16px;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.game-help-row {
  width: 100%;
  min-height: 228px;

  &:only-child {
    grid-row: span 2;
  }
}

.game-help-r-empty {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: rgba(var(--skin-color-1), 0.85);
  overflow: hidden;
  min-height: 190px;

  > img {
    display: block;
    width: 100%;
  }
}

.game-help-r-info {
  width: 100%;
}

.game-help-r-i-title {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 4px;
  text-transform: capitalize;
  font-weight: 600;
}

.game-help-r-i-text {
  font-size: 10px;
  line-height: 16px;
}

// help game END
// game help new section START
.game-help-new-section {
  width: 100%;
  height: 100%;
}

.game-help-container {
  width: 100%;
  height: 100%;
}

.game-help-info-row {
  width: 100%;
  margin-bottom: 20px;

  h3 {
    font-size: 18px;
    font-weight: bold;
    color: rgba(var(--text-color), 1);
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  p {
    font-size: 12px;
    line-height: 18px;
    color: rgba(var(--text-color), 1);
  }
}

.game-help-table-container {
  width: 100%;

  > h3 {
    font-size: 18px;
    font-weight: bold;
    color: rgba(var(--text-color), 1);
    margin-bottom: 10px;
    text-transform: capitalize;
  }
}

.game-help-table {
  width: 100%;
}

.game-help-table-head {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 260px;
  margin-bottom: 4px;
  justify-content: space-between;
  color: rgba(var(--text-color), 0.65);
  font-size: 12px;
  text-transform: capitalize;
}

.game-help-table-content {
  max-width: 260px;
  border: 1px solid rgba(var(--skin-color-white), 0.45);
  border-radius: 6px;
  background-color: rgba(var(--skin-color-1), 0.75);
}

.game-help-table-row {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(var(--skin-color-white), 0.45);
  }
}

.game-help-table-column {
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 10px;
  padding: 0 10px;

  &.left {
    flex: 1;
  }

  &.right {
    flex: 0 0 auto;
    width: 65px;
    justify-content: center;

    [dir='ltr'] & {
      border-left: 1px solid rgba(var(--skin-color-white), 0.45);
    }

    [dir='rtl'] & {
      border-right: 1px solid rgba(var(--skin-color-white), 0.45);
    }
  }

  > span {
    font-size: 12px;
    color: rgba(var(--text-color), 1);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

// game help new section END
// four chance START
.four-chance-cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  //width: 34vmin;
  width: 340px;
  //margin-top: 2vmin;
  margin-top: 18px;
}

.four-chance-head-row {
  display: flex;
  align-items: center;
  //margin-bottom: 2vmin;
  margin-bottom: 18px;

  .single-ch-number {
    //width: 6.8vmin;
    //height: 6.8vmin;
    //font-size: 2.4vmin;
    font-size: 18px;
    width: 46px;
    height: 46px;
  }
}

.four-chance-game-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  //width: 4vmin;
  //height: 4vmin;
  width: 26px;
  height: 26px;
  background: rgba(var(--hero-color), 1);
  border-radius: 50%;
  //font-size: 4vmin;
  font-size: 26px;
  margin: 0 5px;
}

.four-chance-row {
  display: flex;
  //width: 88%;
  width: 270px;
  //margin-bottom: 1vmin;
  margin-bottom: 10px;
}

.four-chance-r-item {
  //width: 4.6vmin;
  //height: 4.6vmin;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(var(--skin-color-1), 0.2);
  margin: 0 2px;

  .single-ch-number {
    width: 100%;
    height: 100%;
    //font-size: 1.8vmin;
    font-size: 14px;
    margin: 0;
  }
}

.four-chance-r-column {
  display: flex;
  align-items: center;
  margin: 0 4px;

  &:first-child {
    flex: 1;
    justify-content: flex-end;
  }

  &:last-child {
    flex: 0 0 60px;
    justify-content: flex-start;
  }
}

.four-chance-r-text {
  //font-size: 1.8vmin;
  font-size: 18px;
  text-transform: uppercase;
  color: rgba(var(--text-color), 0.85);
  font-weight: 900;
}

// four chance END


.skeleton {
  position: relative;
  background: rgba(var(--skin-color-2), 1);
  pointer-events: none;
  overflow: hidden;
  min-height: 30px;
  border-radius: 10px;
  max-width: 360px;
  min-width: 100px;
  margin: 0 auto;
  opacity: 1;
  color: transparent;

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(var(--skin-color-2), 1), rgba(var(--hero-color), 0.5), rgba(var(--skin-color-2), 1));
    animation: progress 1s ease-in-out infinite;
  }
}

.result-skeleton-cnt,
.bet-history-skeleton-cnt {
  //position: absolute;
  position: absolute;
  transform: translate3d(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;

  & ~ * div {
    display: none;
  }
}

.bet-history-skeleton-cnt {
  justify-content: unset;

  .result-skeleton-singles-cnt {
    margin: 46px;
    max-width: 298px;
  }
}

.sk {
  position: relative;
  background: rgba(var(--skin-color-2), 1);
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(var(--skin-color-2), 1), rgba(var(--hero-color), 0.5), rgba(var(--skin-color-2), 1));
    animation: progress 1s ease-in-out infinite forwards;
  }
}

.result-skeleton-img {
  width: 142px;
  height: 142px;
  margin: 26px auto 20px auto;
  border-radius: 50%;
}

.result-skeleton-txt {
  margin: 5px 0;
  max-width: 200px;
  width: 100%;
  border-radius: 12px;
  height: 30px;
}

.bet-h-skeleton-win-lose {
  max-width: 100px;
  height: 22px;
  border-radius: 6px;
  width: 100%;
  margin: 4px 0;
}

.bet-h-skeleton-r {
  margin-top: 32px;
  margin-bottom: 4px;
  height: 48px;
  max-width: 284px;
  width: 100%;
  border-radius: 6px;
}

.bet-h-skeleton-money {
  height: 44px;
  max-width: 100px;
  width: 100%;
  border-radius: 6px;
}

.result-skeleton-singles-cnt {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 308px;
  width: 100%;
}

.result-skeleton-s-r {
  height: 46px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2px;
}

.result-skeleton-s-time {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}

.result-s-s-t-time {
  width: 100px;
  height: 20px;
  border-radius: 20px;
}

.result-skeleton-num {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  flex: 0 0 auto;
  margin-right: 10px;
}

.result-skeleton-s-row {
  flex: 1;
  margin-bottom: 2px;
  width: 100%;
  height: 100%;
  border-radius: 22px;
}

@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.right-to-left {
  transform: translate(0, 0);
}

.choose-animal-anim-enter {
  transform: translate(140%, 0);
  -webkit-transform: translate(140%, 0);
  opacity: 0;
  transition: all 0.2s;
}

